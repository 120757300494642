.dropdown {
    position: absolute;
    right: 270px;
    // bottom: 340px;
    width: 300px;
    z-index: 3;
    background: #ffffff;
    border-radius: 8px;
    padding: 2px 20px;

    .actions {
        position: relative;
        display: flex;
        flex-direction: column;

        .action {
            cursor: pointer;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            border-bottom: 1px solid rgba(0, 0, 0, 0.1);

            span {
                font-style: normal;
                font-weight: normal;
                font-size: 16px;
                line-height: 20px;
            }
        }

        .action:last-child {
            border-bottom: none;
        }
    }
}

.date-dropdown {
    position: absolute;
    right: 25px;
    // top: 255px;
    z-index: 3;
    background: #ffffff;
    border-radius: 8px;
    padding: 2px 20px;

    .actions {
        position: relative;
        display: flex;
        flex-direction: column;
        gap: 10px;
    }
}
.duration-dropdown {
    position: absolute;
    right: 25px;
    bottom: 370px;
    z-index: 3;
    background: #ffffff;
    border-radius: 8px;
    padding: 2px 20px;

    .actions {
        position: relative;
        display: flex;
        flex-direction: column;
        gap: 10px;
    }
}

.otp-dropdown {
    position: absolute;
    right: 270px;
    // top: 215px;
    width: 200px;
    z-index: 3;
    background: #ffffff;
    border-radius: 8px;
    padding: 2px 20px;

    .actions {
        position: relative;
        display: flex;
        flex-direction: column;

        .action {
            cursor: pointer;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            border-bottom: 1px solid rgba(0, 0, 0, 0.1);

            span {
                font-style: normal;
                font-weight: normal;
                font-size: 16px;
                line-height: 20px;
            }
        }

        .action:last-child {
            border-bottom: none;
        }
    }
}

.otp-status {
    position: absolute;
    right: 130px;
    // bottom: 490px;
    z-index: 3;
    background: #ffffff;
    border-radius: 8px;
    padding: 2px 20px;

    .actions {
        position: relative;
        display: flex;
        flex-direction: column;
        gap: 5px;
        .action {
            cursor: pointer;
            border-bottom: 1px solid rgba(0, 0, 0, 0.1);
            margin-top: 0px;
            padding-bottom: 1px;

            span {
                font-style: normal;
                font-weight: normal;
                font-size: 16px;
                // line-height: 20px;
            }
        }

        .action:last-child {
            border-bottom: none;
        }
    }
}

.delivery-status {
    position: absolute;
    right: 130px;
    bottom: 420px;
    z-index: 3;
    background: #ffffff;
    border-radius: 8px;
    padding: 2px 20px;

    .actions {
        position: relative;
        display: flex;
        flex-direction: column;
        gap: 10px;
        .action {
            cursor: pointer;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            border-bottom: 1px solid rgba(0, 0, 0, 0.1);

            span {
                font-style: normal;
                font-weight: normal;
                font-size: 16px;
                line-height: 20px;
            }
        }

        .action:last-child {
            border-bottom: none;
        }
    }
}

.campaign-date-dropdown {
    position: absolute;
    right: 35px;
    top: 200px;
    z-index: 3;
    background: #ffffff;
    border-radius: 8px;
    padding: 2px 20px;

    .actions {
        position: relative;
        display: flex;
        flex-direction: column;
        gap: 10px;
    }
}

.tooltip {
    position: absolute;
    right: 35px;
    top: 200px;
    z-index: 3;
    background: #ffffff;
    border-radius: 8px;
    padding: 2px 20px;
}

@media screen and (min-width: 350px) and (max-width: 414px) {
    .otp-dropdown {
        left: 50px;
        top: 350px;
    }

    .otp-status {
        right: 50px;
        bottom: 360px;
    }
}
