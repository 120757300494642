@import "../../../styles/base.scss";

.accountLayout__container {
  // min-height: 100vh;
  height: 100%;
  padding: 2rem 3rem;
}

.accountLayout--header {
  grid-column: 1/-1;
  height: 200px;
  padding: 1rem;

  h3{
      color: $primary-color-dark;
  }
  p{
      width: 60%;
  }
}

.accountLayout--links {
  display: flex;
}

.accountLayout--children {
  margin-top: 2rem;
  overflow: hidden;
}

.accountLayout--links {
  border-bottom: 1px solid rgb(165, 165, 165);
  a {
    display: inline-block;
    text-decoration: none;
    color: rgb(83, 83, 83);
   
    padding: 1rem 2.5rem;

    &:hover{
        color: $primary-color;
    }
  }
}

.active {
  border-bottom: 4px solid $primary-color;
  color: $primary-color !important;
  border-radius: 8px 8px 0 0 ;
  transition: border ease .35s;
}


