@import "../../../styles/base.scss";

.container {
  margin: 0 auto;
  padding: 5% 0;
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 30px;
  overflow: hidden;
  .metrics--section {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    position: relative;

    &::after {
      content: "";
      position: absolute;
      width: 534px;
      height: 534px;
      left: 1106px;
      top: 120px;
      background: #f1faf8;
      filter: blur(50px);
    }
    h3 {
      font-style: normal;
      font-weight: 700;
      font-size: 32px;
      line-height: 42px;
      /* identical to box height, or 130% */

      color: #000000;
      text-align: center;
    }

    .metric__items {
      display: flex;
      align-items: center;
      gap: 139px;
      z-index: 3;
      .metric__item {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin: 80px;

        .icon {
          background: #ffffff;
          padding: 16px;
          border: 1px solid rgba(57, 65, 73, 0.2);
          border-radius: 50%;
          width: 80px;
          height: 80px;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .number {
          font-style: normal;
          font-weight: 700;
          font-size: 42px;
          line-height: 55px;

          color: #000000;
        }
        .text {
          font-style: normal;
          font-weight: 450;
          font-size: 18px;
          line-height: 31px;
          text-align: center;
          color: #000000;
        }
      }
    }
  }

  .team--section {
    background: #fcfcfc;
    padding: 77px 0px;
    // height: 1138px;
    position: relative;
    bottom: -80px;
    z-index: 1;
    &::after {
      content: "";
      position: absolute;
      width: 874px;
      height: 872px;
      left: 283px;
      top: 266px;

      background: #fff1ce;
      filter: blur(400px);
    }

    h3 {
      font-style: normal;
      font-weight: 700;
      font-size: 56px;
      line-height: 74px;
      position: relative;
      z-index: 1;

      text-align: center;

      color: #000000;
    }

    p {
      font-style: normal;
      font-weight: 450;
      font-size: 24px;
      line-height: 36px;
      position: relative;
      z-index: 1;

      text-align: center;

      /* gray/2 */

      color: #969696;
    }

    &__members {
      position: relative;
      z-index: 1;

      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      gap: 50px;
      flex-wrap: wrap;
      margin-top: 119px;

      .team--member {
        display: flex;
        flex-direction: column;
        align-items: baseline;
        justify-content: center;

        &__name {
          font-style: normal;
          font-weight: 700;
          font-size: 20px;
          line-height: 31px;
          text-align: center;
          margin-bottom: 4px;

          color: #000000;
        }

        &__position {
          font-style: normal;
          font-weight: 450;
          font-size: 16px;
          line-height: 31px;
          margin: 0;

          text-align: center;

          color: #000000;
        }

        img {
          width: 316px;
          height: 278px;
          object-fit: cover;
        }
      }
    }
  }
}

.header__details {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  div {
    width: 868px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    h3 {
      font-style: normal;
      font-weight: 700;
      font-size: 56px;
      line-height: 74px;
      /* or 132% */

      text-align: center;

      color: #ffffff;
    }

    p {
      font-style: normal;
      font-weight: 450;
      font-size: 24px;
      line-height: 36px;
      /* or 150% */

      text-align: center;

      /* gray/4 */

      color: #f4f4f4;
    }
  }

  img {
    margin-top: -50px;
    width: 50%;
    height: 50%;
  }
}

.mission__flex {
  display: flex;
  margin-bottom: 20%;

  .mission__child {
    margin-right: 10%;

    .heading {
      display: flex;

      span {
        align-self: center;
        margin-left: 5%;
        font-size: 1.3rem;
        font-weight: 600;
      }
    }

    p {
      color: $text-light;
    }
  }
}

.details__box {
  display: flex;
  justify-content: space-between;
  margin: 10% 0;

  .box__text {
    width: 42%;
    padding-top: 5%;

    .box__title {
      font-size: 3rem;
      line-height: 3rem;
      font-weight: bold;
      margin-bottom: 5%;
    }

    .box__subtext {
      line-height: 2rem;
      color: $text-light;
      font-size: 1.2rem;
    }
  }

  .verify__text {
    align-self: center;
  }
}

.story__box {
  margin-bottom: 5%;
}

@media (width: 1920px) {
  .container {
    .team--section {
      &__members {
        gap: 150px;
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .container {
    width: max-content;
    overflow: hidden;
    .metrics--section {
      width: 375px;
      &::after {
        content: "";
        position: absolute;
        width: 344px;
        height: 285.54px;
        left: 18px;
        top: 159px;

        background: #f1faf8;
        filter: blur(300px);
        transform: rotate(17.87deg);
      }

      h3 {
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 30px;
        text-align: center;
        width: 289px;
        color: #000000;
      }
      .metric__items {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        padding: 0px;
        margin: 62px 0 0 0;
        gap: 20px;

        .metric__item {
          flex: none;
          order: 1;
          flex-grow: 0;
          margin: 0px 9px;

          .number {
            font-style: normal;
            font-weight: 700;
            font-size: 24px;
            line-height: 36px;
            text-align: center;
            width: 143px;
          }

          .text {
            font-style: normal;
            font-weight: 450;
            font-size: 14px;
            line-height: 20px;
            width: 143px;
            text-align: center;
          }

          img {
            padding: 8px;
          }
        }
        .metric__item:nth-child(2) {
          order: 2;
        }
      }
    }

    .team--section {
      position: relative;
      width: 375px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      &::after {
        content: "";
        position: absolute;
        width: 353.27px;
        height: 320.43px;
        left: 10px;
        top: 296px;

        background: #fff1ce;
        filter: blur(400px);
        transform: rotate(29.99deg);
      }

      h3 {
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 36px;
      }

      p {
        font-style: normal;
        font-weight: 450;
        font-size: 16px;
        line-height: 20px;
        text-align: center;
        width: 282px;
        color: #969696;
      }
      &__members {
        align-items: baseline;
        justify-content: space-between;
        gap: 15px;
        margin-top: 60px;
        .team--member {
          width: 179px;
          justify-content: baseline;

          &__name {
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 20px;
            text-align: left;
          }

          &__position {
            font-style: normal;
            font-weight: 450;
            font-size: 10px;
            line-height: 13px;
            /* identical to box height */

            text-align: left;

            color: #000000;
          }
          img {
            width: 100%;
            height: 180px;
          }
        }
      }
    }
  }

  .header__details {
    width: 100%;
    div {
      width: 343px;
      justify-content: flex-start;
      align-items: baseline;
      h3 {
        font-style: normal;
        font-weight: 700;
        font-size: 32px;
        line-height: 42px;
        text-align: left;
      }
      p {
        font-style: normal;
        font-weight: 450;
        font-size: 16px;
        line-height: 24px;
        text-align: left;
      }
    }
  }
}

@media (max-width: $break-point-xl) {
  .container {
    // width: 90%;
  }

  .header__details {
    flex-direction: column;
    height: 100%;
    padding-top: 10%;

    div {
      margin-bottom: 40px;
      margin-right: 0;
      margin-top: 0;
      // width: 343px;

      h3 {
        font-size: 1.5rem;
        line-height: 2rem;
      }
    }

    img {
      margin-top: -100px;
      height: 100%;
      width: 100%;
    }
  }

  .mission__flex,
  .details__box {
    flex-direction: column;
  }

  .mission__flex {
    width: 100%;

    .mission__child {
      margin-right: 0;
      margin-bottom: 5%;

      .heading {
        img {
          width: 10%;
        }

        span {
          font-size: 1rem;
        }
      }
    }
  }

  .details__box img,
  .story__box div {
    margin-bottom: 10%;
  }

  .details__box {
    margin-top: 0;
    margin-bottom: 25%;

    .box__text {
      width: 100%;
      padding-top: 0;

      .box__title {
        font-size: 1.8rem;
        line-height: 2rem;
      }

      .box__subtext {
        font-size: 0.9rem;
      }
    }

    img {
      width: 90%;
      height: auto;
    }
  }

  .details__box__reverse__order {
    flex-direction: column-reverse;
  }

  .story__box {
    margin-bottom: 20%;
  }
}
