.back {
    display: flex;
    gap: 1rem;
    align-items: center;
    cursor: pointer;

    span {
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 26px;
        /* identical to box height */

        color: #eb2931;
    }
}
.container {
    margin-top: 1rem;
    width: 100%;

    padding: 1.5rem;
    background: #ffffff;
    border-radius: 10px;
    display: flex;
    justify-content: space-between;

    .form--section {
        width: 40%;
        h3 {
            // font-family: "Inter";
            font-style: normal;
            font-weight: 500;
            font-size: 30px;
            line-height: 36px;

            color: #000000;
        }
        p {
            // font-family: "Inter";
            font-style: normal;
            font-weight: normal;
            font-size: 18px;
            line-height: 28px;
            /* identical to box height, or 156% */

            display: flex;
            align-items: center;

            color: #8692a6;
        }

        .form__group {
            display: flex;
            flex-direction: column;
            margin: 1rem 0;

            input,
            textarea {
                border-radius: 6px;
                padding: 1rem;
            }
            textarea {
                height: 150px;
                border: 1px solid #ccc;
                resize: none;
            }
        }

        .requirements--info {
            display: flex;
            justify-content: space-between;
            text-align: right;
            // border: 1px solid red;
        }

        .requirement--list {
            border-left: 5px solid rgb(214, 174, 115);
            list-style: none;
            font-size: 12px;
            line-height: 28px;

            color: #000000;
        }
    }
}
.radio--buttons {
    padding: 1rem 0;
    display: flex;
    justify-content: space-between;
    margin-left: -4rem;

    label {
        font-style: normal;
        font-weight: normal;
        font-size: 15px;
        line-height: 20px;
        width: 250px;
        letter-spacing: 0.2px;
        margin-left: -3rem;
        color: #000000;
    }
}

.upload--dialog {
    background: #ffffff;
    border-radius: 20px;
    max-height: 95vh;
    min-height: 85vh;
    overflow: auto;
    margin-bottom: 1rem;

    p {
        display: flex;
        gap: 1rem;
        align-items: center;
        cursor: pointer;

        span {
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 26px;
            /* identical to box height */

            color: #eb2931;
        }
    }
    .title {
        font-style: normal;
        font-weight: bold;
        font-size: 22px;
        line-height: 36px;
        display: flex;
        align-items: center;

        color: #000000;
    }

    .info {
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 28px;
        display: flex;
        align-items: center;
        padding-top: 0.5rem;
        color: #8692a6;
    }

    .download {
        height: 40px;
        background: #ffffff;
        border: 1.5px solid #000000;
        box-sizing: border-box;
        border-radius: 6px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        a {
            display: flex;
            P {
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                line-height: 31px;
                display: flex;
                align-items: center;
                text-align: center;
                padding-right: 1rem;
                color: rgba(21, 134, 216, 0.9);
            }
        }
    }

    .form__group {
        display: flex;
        flex-direction: column;
        margin-top: 1.5rem;

        input,
        textarea {
            border-radius: 6px;
            padding: 1rem;
        }
        textarea {
            height: 150px;
            border: 1px solid #ccc;
            resize: none;
        }
    }

    .upload--form {
        margin-top: 1rem;
        background: #ffffff;
        box-shadow: 0px 15px 20px rgba(123, 123, 123, 0.25);
        border-radius: 10px;
        padding: 0.5rem;

        span {
            display: flex;
            justify-content: center;
        }
        .filename {
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 13px;
            display: flex;
            justify-content: center;
            margin-top: 2rem;
            color: #828282;
        }
    }
}
