@import "../../../styles/base.scss";

.tabs {
    display: flex;
    gap: 25px;
    align-items: center;
    position: relative;
    margin-bottom: 2rem;
    .underline {
        width: 363px;
        height: 2px;
        bottom: -4px;
        position: absolute;
        background: #828282;
    }

    .campaign {
        width: 105px;
        height: 2px;
        background: #eb2931;
        position: absolute;
        bottom: -4px;
    }
    .sms {
        width: 60px;
        height: 2px;
        background: #eb2931;
        position: absolute;
        bottom: -4px;
        left: 105px;
        z-index: 3;
    }
    .otp {
        width: 60px;
        height: 2px;
        background: #eb2931;
        position: absolute;
        bottom: -4px;
        left: 165px;
    }
    .voice {
        width: 138px;
        height: 2px;
        background: #eb2931;
        position: absolute;
        bottom: -4px;
        left: 225px;
    }
    .tab {
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 23px;
        cursor: pointer;
        text-align: left;
        // border-bottom: 5px solid #828282;
    }

    tab::before {
        padding-right: 0px;
    }

    .active-tab {
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 23px;
        cursor: pointer;
        color: #eb2931;
        // padding-bottom: 5px;
    }
}

.cards--container,
.cards--item {
    display: flex;
    gap: 30px;
    // justify-content: space-evenly;
}

.cards--container {
    margin-top: 2rem;
    margin-bottom: 2rem;
}

.cards--item {
    background-color: #fff;
    padding: 1.5rem;
}

.cards--item {
    align-items: flex-start;
    border-radius: 1rem;
    margin-right: 1rem;
    min-width: 277px;
    // border: 1px solid $border-color ;
    box-shadow: 0px 8px 10px #ccc;

    .metrics {
        display: flex;
        justify-content: space-between;
        align-items: center;
        min-width: 230px;
        align-content: center;
    }

    h2 {
        color: $primary-color-dark;
        margin: 0;
        font-size: 28px;
    }

    p {
        color: $text-light;
    }

    .icon {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background: #ffb8bb;
        // opacity: 0.3;
    }

    .icon,
    svg {
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.table--top {
    display: flex;
    align-items: center;
    margin-bottom: 2rem;
    gap: 40px;
    float: right;
    .search-input {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        input {
            width: 324px;
            height: 56px;
            background: #ffffff;
            border-radius: 8px;
            border: none;
            padding-left: 50px;
        }
        input::placeholder {
            font-style: normal;
            font-weight: 500;
            font-size: 18px;
            line-height: 23px;
            letter-spacing: 0.2px;
            color: #c4c4c4;
        }
        span {
            position: absolute;
            left: 20px;
        }
    }
}

.table--container {
    height: auto;
    margin-top: 20px;

    border-radius: 7px;
    .empty--table {
        top: 150px;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        position: relative;
        left: 370px;

        p {
            font-style: normal;
            font-weight: bold;
            font-size: 18px;
            line-height: 23px;
            text-align: center;
            letter-spacing: 0.5px;

            color: #828282;
        }
    }

    tbody .table--body {
        background: #ffffff;
        height: 80px;
        cursor: pointer;
        box-sizing: border-box;
        border-radius: 7px;
    }

    th:first-child {
        border-top-left-radius: 7px;
        border-bottom-left-radius: 0px;
    }
    th:last-child {
        border-top-right-radius: 7px;
        border-bottom-right-radius: 0px;
    }
    table th {
        background: #eb2931;
    }

    table tr:nth-child(odd) {
        background-color: rgba(229, 229, 229, 0.4);
    }
}

.new,
.failed,
.verified,
.success,
.sent {
    font-size: 15px;
    padding: 2px 23px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
}

.new {
    color: rgba(21, 101, 216, 0.7);
    background: rgba(207, 227, 255, 0.5);
    border: 2px solid rgba(21, 101, 216, 0.7);
}

.failed {
    color: #eb2931;
    background: rgba(235, 41, 49, 0.2);
    border: 2px solid #eb2931;
}

.success {
    color: #219681;
    background: rgba(61, 255, 92, 0.2);
    border: 2px solid rgba(9, 100, 7, 0.2);
}
.sent {
    color: #ffbe21;
    background: rgba(255, 190, 33, 0.2);
    border: 2px solid #ffbe21;
}
