/*** Variables ***/

/* ==================
==== Colors ==========
=====================
**/

$redGradient: linear-gradient(
  331.03deg,
  rgba(117, 0, 5, 0.7) 13.22%,
  rgba(255, 45, 53, 0.7) 86.79%
);

$primary-color: #eb2931;
$primary-color-dark: #750005;
$border-radius: 6px;
$border-color: #8692a6;
$text-light: #8692a6;
$text-dark: #1d293f;
$label-color: #696f79;

/*===================
===== Breakpoints ==========
=====================
**/

$break-point-s: 576px;
$break-point-md: 782px;
$break-point-l: 992px;
$break-point-xl: 1024px;
$break-point-xxl: 1200px;

/* ==================
==== Fonts ==========
=====================
**/

@font-face {
  font-family: "circular_std_book";
  src: url("../assets/Fonts/Circular_Std/Circular-Std-Book.ttf")
    format("truetype");
  font-display: swap;
}
@font-face {
  font-family: "circular_std_black";
  src: url("../assets/Fonts/Circular_Std/Circular-Std-Black.ttf")
    format("truetype");
  font-display: swap;
}
@font-face {
  font-family: "circular_std_medium";
  src: url("../assets/Fonts/Circular_Std/Circular-Std-Medium.ttf")
    format("truetype");
  font-display: swap;
}
@font-face {
  font-family: "circular_std_bold";
  src: url("../assets/Fonts/Circular_Std/Circular-Std-Bold.ttf")
    format("truetype");
  font-display: swap;
}

/*** Extends ***/

%h {
  font-family: "circular_std_bold";
  margin: 1rem 0 0 0;
  // font-weight: bold;
}

%h1 {
  @extend %h;
  font-size: 34px;
  line-height: 1.25em;
}

%body-medium {
  font-family: "circular_std_medium";
  font-size: 18px;
}

%body-normal {
  font-family: "circular_std_book";
  font-size: 16px;
}

h1 {
  @extend %h;
  font-size: 44px;
  line-height: calc(44px * 1.3);
}
h2 {
  @extend %h;

  font-size: 36px;
  line-height: calc(36px * 1.3);
}
h3 {
  @extend %h;

  font-size: 28px;
  line-height: calc(28px * 1.3);
}
h4 {
  @extend %h;

  font-size: 24px;
  line-height: calc(24px * 1.3);
}

label,
p,
li,
ul,
span {
  @extend %body-normal;
  font-size: 16px;
  font-style: normal;
  margin: 0.5rem 0;
  line-height: calc(16px * 1.7);
}

// Audio recorder style
.controls-container {
  height: 180px;
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.recorder-display {
  // width: 50%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 2rem;
  height: 50px;
}

@keyframes animated-block {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.recording-indicator {
  width: 10px;
  height: 10px;
  margin-right: 0.5rem;
  border-radius: 50%;
  background-color: #099fff;
  animation-name: animated-block;
  animation-duration: 2s;
  animation-iteration-count: infinite;
}
.line-indicator {
  width: 384px;
  height: 4px;
  background: rgba(237, 237, 237, 0.8);
  border-radius: 20px;
}
.recording-time {
  // width: 80%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cancel-button-container {
  // width: 20%;
  display: grid;
  place-content: center;
  animation-name: animated-block;
  animation-duration: 2s;
}

.start-button,
.cancel-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px;
  height: 39px;
  cursor: pointer;
  background: #eb2931;
  border-radius: 8px;
}

.cancel-button {
  width: 92px;
  height: 39px;
  color: #fff;
}

.start-button-container {
  margin-top: 20px;
  display: grid;
  float: right;
}

.start-button {
  color: #fff;
  display: flex;
  align-items: center;
  gap: 1rem;
}

.action-buttons {
  display: flex;
  gap: 2rem;
  align-items: center;
}

.recording--filled {
  display: flex;
  align-items: center;
  gap: 5px;
  background: $primary-color;
  border-radius: 5px;
  padding: 3px 11px 3px 8px;
  color: #fff;
  cursor: pointer;
}
