@import "../../styles/base.scss";

.pagination__container {
  display: flex;
  flex-direction: row;
  margin: 1rem auto;
  align-items: center;
  button {
    background-color: rgb(156, 60, 60);
    width: 10%;
    margin-right: 1rem;
    &:last-of-type {
      margin-left: 1rem;
    }
    a,
    a:visited {
      color: #fff;
    }
    &:disabled {
      background-color: rgb(156, 60, 60);
      opacity: 0.3;
      cursor: not-allowed;
    }
  }

  li {
    display: inline-block;
    border: 1px solid #ccc;
    padding: 0.5rem 1rem;

    &:hover {
      background: rgb(156, 60, 60);
      cursor: pointer;
      > a {
        color: #ffffff;
      }
    }

    a {
      color: #000000;
    }
  }

  .active {
    a {
      color: #ffffff;
    }
    background: rgb(156, 60, 60);
  }
}

.blog--pagination__container {
  display: flex;
  flex-direction: row;
  margin: 1rem auto;
  align-items: center;
  gap: 1rem;
  .prev--button {
    width: 32px;
    height: 32px;
    background: #919eab;
    opacity: 0.5;
    border-radius: 4px;
    display: grid;
    place-items: center;
  }
  .next--button {
    width: 32px;
    height: 32px;
    background: #ffffff;
    border: 1px solid #dfe3e8;
    border-radius: 4px;
    display: grid;
    place-items: center;
  }

  li {
    display: inline-block;
    border: 1px solid #ccc;
    padding: 1.5px 11px;
    margin-left: 5px;
    border: 1px solid #dfe3e8;
    border-radius: 4px;
    &:hover {
      background: rgb(156, 60, 60);
      cursor: pointer;
      > a {
        color: #ffffff;
      }
    }

    a {
      color: #000000;
    }
  }

  .active {
    border: 1px solid rgb(156, 60, 60);
  }
}
@media screen and (max-width: 900px) {
  .pagination__container {
    button {
      width: 30%;
    }
  }
}
