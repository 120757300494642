.table--top {
    display: flex;
    justify-content: space-between;
    p {
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 23px;
        letter-spacing: 0.2px;

        color: #eb2931;
    }
}

.table--container {
    height: auto;
    margin-top: 20px;
    background: #ffffff;
    tbody .table--body {
        background: #ffffff;
        text-align: left;
        border: 1px solid rgba(0, 0, 0, 0.4);
        box-sizing: border-box;
        border-radius: 7px;
    }

    th:first-child {
        border-top-left-radius: 7px;
        border-bottom-left-radius: 0px;
    }
    th:last-child {
        border-top-right-radius: 7px;
        border-bottom-right-radius: 0px;
    }
    table th {
        background: #eb2931;
    }

    table tr:nth-child(odd) {
        background-color: rgba(229, 229, 229, 0.4);
    }
}
.deleteModal {
    width: 366px;
    height: 189px;

    background: #ffffff;
    border-radius: 20px;
    .buttons {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        margin-top: 2rem;
    }
    p {
        font-style: normal;
        font-weight: bold;
        font-size: 30px;
        line-height: 36px;
        display: flex;
        align-items: center;

        color: #000000;
    }
}
