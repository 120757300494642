@import "../../../styles/base.scss";

.section__survey--one,
.section__survey--two,
.section__survey--three {
    background: #ffffff;
}

.section__survey--one {
    align-items: center;
    background: #fffbf0;
    .container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        flex-wrap: wrap;
        padding: 50px;
        .container--left {
            width: 45%;

            h1 {
                font-style: normal;
                font-weight: bold;
                font-size: 42px;
                line-height: 55px;
                /* or 130% */
                position: sticky;
                top: 0;
            }
        }
        .container--right {
            width: 50%;
            flex-direction: column;
            ul {
                li {
                    margin: 0;
                    display: flex;
                    gap: 10px;
                    align-items: center;
                    flex-direction: row;

                    span {
                        font-style: normal;
                        font-weight: 450;
                        font-size: 18px;
                        line-height: 31px;
                        padding-top: 21px;
                    }
                }
            }
            div.section__survey--img {
                img {
                    width: 100%;
                    height: 500px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
            }
        }
    }
}

.section__survey--two {
    background: #ffffff;
    position: relative;
    .circle {
        width: 506px;
        height: 506px;
        background: #fff1ce;
        filter: blur(400px);
        position: absolute;

        top: 50px;
    }
    .accordion {
        position: relative;
        z-index: 3;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 83px 0px;
    }
    .accordion__title {
        font-style: normal;
        font-weight: bold;
        font-size: 32px;
        line-height: 42px;
        color: #000000;
    }

    .accordion__list {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        padding: 0 14px 14px 14px;
        cursor: pointer;
        width: 980px;

        background: #ffffff;
        border: 1px solid rgba(57, 65, 73, 0.2);
        box-sizing: border-box;
        border-radius: 12px;

        flex: none;
        order: 0;
        flex-grow: 0;
        margin: 18px 0px;

        .header {
            display: flex;
            width: 930px;
            justify-content: space-between;
            align-items: center;
            h3 {
                font-style: normal;
                font-weight: 500;
                font-size: 18px;
                line-height: 31px;
                color: #000000;
            }
        }

        p {
            font-style: normal;
            font-weight: 450;
            font-size: 18px;
            line-height: 31px;
            /* or 170% */

            color: #8b8d92;
        }
    }
}

.header__details {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    button {
        margin: auto;
    }

    div {
        // margin-bottom: 20%;
        h3 {
            font-style: normal;
            font-weight: bold;
            font-size: 56px;
            line-height: 74px;
            text-align: center;

            color: #ffffff;
        }
        .third__header__paragraph {
            width: 637px;
            margin-bottom: 3%;
            font-style: normal;
            font-weight: 450;
            font-size: 24px;
            line-height: 36px;
            text-align: center;

            color: #f4f4f4;
        }
    }

    img {
        width: 50%;
        height: 50%;
    }
}

.header__main__text {
    width: 60%;
    font-size: 3rem;
}

@media screen and (max-width: 600px) {
    .header__details {
        div {
            overflow: hidden;
            h3 {
                font-style: normal;
                font-weight: bold;
                font-size: 32px;
                line-height: 42px;
                /* identical to box height, or 130% */

                text-align: center;

                color: #ffffff;
                margin: 0 0 8px 0;
            }
            .third__header__paragraph {
                font-style: normal;
                font-weight: 450;
                font-size: 16px;
                line-height: 24px;
                /* or 150% */

                text-align: center;
                width: 343px;

                /* gray/4 */

                color: #f4f4f4;
            }
            button {
                width: 100%;
            }
        }
    }

    .section__survey--one {
        padding-top: 0 !important;
        .container {
            flex-direction: column;
            justify-content: flex-start;
            padding: 20px;
            width: 100%;
            .container--left {
                h1 {
                    font-style: normal;
                    font-weight: bold;
                    font-size: 24px;
                    line-height: 36px;
                    /* or 150% */
                    width: 312px;
                    color: #000000;
                }
            }

            .container--right {
                width: 100%;
                ul {
                    padding: 5px;

                    li {
                        span {
                            padding-top: 10px;
                        }
                        span:last-child {
                            font-style: normal;
                            font-weight: 450;
                            font-size: 14px;
                            line-height: 24px;
                            width: 343px;
                        }
                    }
                }
            }
        }
    }
    .section__survey--two {
        overflow: hidden;
        .circle {
            position: absolute;
            width: 280px;
            height: 280px;
            left: -83px;
            top: 527px;

            background: #f7e0a8;
            filter: blur(200px);
        }
        .accordion {
            padding: 41px 0px 37px 0;
            .accordion__title {
                text-align: center;
                font-style: normal;
                font-weight: bold;
                font-size: 24px;
                line-height: 36px;
                width: 201px;
            }
            .accordion__list {
                width: 343px;
                background: #ffffff;
                border: 1px solid rgba(202, 210, 218, 0.2);
                box-sizing: border-box;
                border-radius: 4px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;
                padding: 24px 16px;
                .header {
                    h3 {
                        font-weight: 500;
                        font-size: 14px;
                        line-height: 18px;
                    }

                    width: 100%;
                }
                p {
                    font-style: normal;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 130%;
                    /* or 18px */

                    /* gray/2 */

                    color: #969696;
                }
            }
        }
    }
}

@media (max-width: $break-point-xl) {
    .header__details {
        flex-direction: column;
        height: 100%;
        padding-top: 10%;

        div {
            margin-bottom: 0;

            h3 {
                font-size: 1.5rem;
                line-height: 2rem;
            }
        }

        img {
            margin-top: -80px;
            height: 100%;
            width: 100%;
        }
    }
}

@media screen and (max-width: 900px) {
    .section__survey--one,
    .section__survey--two,
    .section__survey--three {
        grid-template-columns: repeat(1, 1fr);
    }

    .header__main__text {
        width: 100%;
        font-size: 2rem;
    }

    .third__header__paragraph {
        width: 100%;
        margin-bottom: 5%;
        font-size: 1rem;
    }

    .header__contents {
        h1 {
            font-size: 2rem;
            line-height: 2.3rem;
        }

        p {
            font-size: 1rem;
            line-height: 1.3rem;
        }
    }

    .section__survey--one {
        padding-top: 20%;
    }

    // .header__details {
    //   flex-direction: column;
    //   height: 100%;
    //   padding-top: 30%;

    //   div {
    //     h3 {
    //       font-size: 1.5rem;
    //       line-height: 2rem;
    //     }
    //   }

    //   img {
    //     height: 100%;
    //     width: 100%;
    //   }
    // }
}
