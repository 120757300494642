@import "../../../styles/base.scss";

.header__details {
  display: flex;
  align-items: center;
  // padding-top: 7%;

  div {
    // margin-top: 120px;
    text-align: left;
    margin-bottom: 10%;
    margin-right: 100px;
    h3 {
      margin-bottom: 6%;
      font-size: 2.8rem;
      font-weight: bold;
      line-height: 4rem;
    }
  }

  img {
    margin-top: -50px;
    width: 50%;
    height: 50%;
  }
}

.section__one,
.section__two,
.section__three {
  padding: 8% 20% 6%;
}

.form__btn__group,
.section__form__one {
  display: flex;
}

.component__right,
.component__left {
  width: 100%;
}

.section__two {
  background: #fef4f5;
}

.section__title__box {
  text-align: center;

  .section__title {
    font-size: 3rem;
    line-height: 3rem;
    font-weight: bold;
  }

  .section__subtitle {
    width: 45%;
    margin: 1.5% auto 0;
    color: $text-light;
  }
}

.section__form__one {
  justify-content: space-between;
  margin-top: 8%;

  div {
    width: 100%;
  }
}

.section__form__three {
  width: 60%;
  margin: 5% auto 0;

  form {
    margin-bottom: 30px;
  }

  p {
    color: #1d293f;
  }
}

.component__right {
  margin-right: 4%;
}

.form__btn {
  width: 40%;
}
.demo--container {
  background-color: #f6f7fb;
  display: flex;
  justify-content: center;
  flex-direction: column;
  position: relative;
  z-index: 50;
  padding-left: 50px;
  padding-right: 50px;

  &__demos {
    display: flex;
    gap: 16px;
    flex-wrap: wrap;
    padding: 20px 0px;

    .demo--item {
      height: 490px;
      width: 426px;
      background-color: #ffffff;

      &__video {
        width: 426px;
        height: 295px;
        padding: 2rem;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      &__text {
        padding: 24px;
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .demo--container {
    padding-left: 20px;
    padding-right: 20px;

    &__demos {
      .demo--item {
        width: 100%;

        &__video {
          width: 100%;
          padding: 1.5rem;
        }
      }
    }
  }
}
