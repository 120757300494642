@import "../../../styles/base.scss";

.main {
  align-items: center;
  // border: 1px solid red;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 80%;

  .create {
    display: flex;
    align-items: center;
    margin-top: 50px;
    justify-content: center;
    color: $primary-color-dark;
  }
  h4 {
    font-size: 23px;
    font-weight: 600;
    margin: 0;
    margin-right: 15px;
  }

  span {
    font-size: 23px;

    span {
      font-size: 23px;
      text-decoration: underline;
      cursor: pointer;
    }
  }
}

.cards--container,
.cards--item {
  display: flex;
  // justify-content: space-evenly;
}

.cards--container {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.cards--item {
  background-color: #fff;
  padding: 1.5rem;
}

.cards--item {
  align-items: flex-start;
  border-radius: 1rem;
  margin-right: 1rem;
  width: 250px;
  // border: 1px solid $border-color ;
  box-shadow: 0px 8px 10px #ccc;

  h2 {
    color: $primary-color-dark;
    margin: 0;
  }

  p {
    color: $text-light;
  }
}

.actionarea {
  display: flex;
  justify-content: flex-end;
  button {
    font-size: 16px;
    width: 200px;
    padding: 0;

    span {
      display: inline;
      margin-right: 10px;
    }
  }
}

table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  * {
    text-align: left;
  }
  thead {
    tr {
      background-color: $primary-color;
      color: #fff;
    }
  }
  td,
  th {
    padding: 1rem;
    text-align: center;
    vertical-align: middle;
    // border: 1px solid red;
  }
}

.table-row {
  border-top: 1em solid transparent;
  box-shadow: 0px 2px 5px #ccc;
}

.top {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: flex-start;

  h2 {
    font-size: 30px;
    text-align: left;
  }
  // text-align: left;
  p {
    color: $text-light;
    font-size: 18px;
  }
}

form {
  margin-top: 15px;
  label {
    display: block;
  }

  input,
  textarea {
    width: 100%;
  }

  button {
    margin-top: 30px;
  }
}

.new,
.pending,
.verified,
.confirmed {
  font-size: 15px;
  padding: 7px 23px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 15px;
}

.new {
  color: rgba(21, 101, 216, 0.7);
  background: rgba(207, 227, 255, 0.5);
  border: 2px solid #1565d8;
}

.pending {
  color: #eb2931;
  background: rgba(235, 41, 49, 0.2);
  border: 2px solid #eb2931;
}

.confirmed {
  color: #219681;
  background: rgba(61, 255, 92, 0.2);
  border: 2px solid rgba(9, 100, 7, 0.2);
}

@media screen and (max-width: 900px) {
  .cards--container {
    flex-direction: column;
    // margin-bottom: 2rem;
  }

  .cards--item {
    width: 100%;
  }

  .table--container {
    display: block;
    width: 100%;
    overflow-x: auto;

    table {
      width: 1000px;
    }
  }

  .paginate {
    width: 100%;
  }

  .main {
    height: 70%;
  }

  .create {
    flex-direction: column;

    span {
      font-size: 20px;

      span {
        font-size: 20px;
      }
    }
  }
}
