// @import-normalize;

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

._1ceqH ._dt3-T {
  background: #fff !important;
  border-radius: 10px !important;
  border: 1px solid #ccc;
}

._1Yplu {
  display: flex;

  ._1Pz2d._37kfa {
    display: none;
  }

  ._1Pz2d._2gd2_ {
    margin-left: 15px;
    width: 149px;
    background: red !important;
    color: #fff !important;
    border: none !important;
  }
}

._1ceqH ._3bC73 ._1YOWG {
  position: absolute;
  left: 50%;
  transform: translate(-50%);
  bottom: 100px;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  color: #000;
}

._1ceqH ._f2DT8 span {
  color: #000;
}

// .button__bar {
//   display: inline-block;
//   vertical-align: middle;
//   margin: auto;
//   padding: 0;
//   border-radius: 40px;
//   width: auto;
//   text-align: center;
// }

// .button__bar li {
//   list-style: none;
//   cursor: pointer;
//   display: inline-block;
//   margin: 0 3px;
//   padding: 0;
// }

// .button__bar li button {
//   border: none;
//   background: #c4c4c4;
//   color: transparent;
//   cursor: pointer;
//   display: block;
//   height: 15px;
//   width: 15px;
//   border-radius: 7.5px;
// }

// .button__bar li.slick-active button {
//   background: #eb2931;
// }

// .slick-slide img {
//   display: block;
//   margin: auto;
// }

// .slide {
//   opacity: 0;
//   /* filter: blur(4px); */
//   position: absolute;
//   z-index: 10;
//   margin: auto;
//   transition-duration: 1s ease;
//   width: 320px;
// }

// .slide.active {
//   position: relative;
//   opacity: 1;
//   transition-duration: 1s;
//   transform: scale(1.5);
//   filter: drop-shadow(0px 4px 100px rgba(0, 0, 0, 0.5));
// }

// .slick-slide {
//   .testimonial--card {
//     margin: 30px 58px;
//     background: #fff;
//     border-radius: 4px;
//     width: auto !important;
//     padding: 20px;
//   }

//   .testimonial--card__text {
//     width: 395px;

//     font-style: normal;
//     font-weight: 450;
//     font-size: 14px;
//     line-height: 24px;
//     text-align: justify;

//     color: #323232;

//     flex: none;
//     order: 1;
//     flex-grow: 0;
//     margin: 0px 0px 114px 0px;
//   }
// }

// .slick-center {
//   transform: scale(1.3);
//   filter: drop-shadow(0px 4px 100px rgba(0, 0, 0, 0.5));
//   padding: 50px 0px;
//   .testimonial--card {
//     margin: 30px 53px;
//     background: #9d1b21;
//     border-radius: 4px;
//     width: auto !important;
//     color: #fff !important;
//     padding: 20px;
//   }

//   .card--footer {
//     display: flex;
//     flex-direction: row;
//     align-items: center;
//     margin: 30px;
//   }

//   svg {
//     padding-top: 20px;
//     path {
//       fill: #fff;
//     }
//   }

//   .customer--details {
//     padding-left: 17px;
//   }

//   .customer--details__name {
//     font-style: normal;
//     font-weight: bold;
//     font-size: 16px;
//     line-height: 26px;
//     padding: 0px;
//     color: #fff;
//   }

//   .customer--details__company {
//     font-style: normal;
//     font-weight: 450;
//     font-size: 12px;
//     line-height: 22px;
//     padding: 0px;
//     color: #fff;
//   }

//   .testimonial--card__text {
//     width: 398px;
//     font-style: normal;
//     font-weight: 450;
//     font-size: 16px;
//     line-height: 34px;

//     color: #f4f4f4;

//     flex: none;
//     order: 1;
//     flex-grow: 0;
//     margin: 0px 0px 32px 0px;
//   }
// }

// @media screen and (max-width: 600px) {
//   .slick-slide {
//     .testimonial--card {
//       display: none;
//       margin: 20px;
//       background: #fff;
//       border-radius: 4px;
//       padding: 0px;
//       display: none;
//     }
//     .testimonial--card__text {
//       font-style: normal;
//       font-weight: 450;
//       font-size: 16px;
//       line-height: 29px;
//       color: #323232;
//       text-align: justify;
//       width: 320px;
//     }
//     svg {
//       padding-top: 1rem;
//       path {
//         fill: #000;
//       }
//     }
//   }
//   .slick-center {
//     transform: none;
//     filter: none;
//     .testimonial--card {
//       margin-left: 10px;
//       background: #9d1b21;
//       border-radius: 4px;
//       padding: 10px;
//     }

//     .testimonial--card__text {
//       font-style: normal;
//       font-weight: 450;
//       font-size: 16px;
//       line-height: 29px;
//       color: #f4f4f4;
//       text-align: justify;
//       width: 320px;
//     }
//     svg {
//       padding-top: 1rem;
//       path {
//         fill: #fff;
//       }
//     }
//   }
// }
