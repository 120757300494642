@import "../../../styles/base.scss";

.admin__container{
    width: 100%;
}

.admin__info{
    p{
        color: $text-light;
    }
}

.payments__container,
.payments__card{
    display: flex;
}

.payments__container{
    flex-direction: row;
    justify-content: space-between;
    width: 80%;
    margin: 2rem auto;
}

.payments__card{
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #FFFFFF;
    padding: 1rem;
    border-radius: $border-radius;
    width: 45%;
    box-shadow: 0px 3px 5px #ccc;
    
    div.viewmore{
        margin-left: auto;
    }
}

.circle-progress {
  display: inline-block;
  position: relative;
  width: 120px;
  height: 120px;
  margin: 1rem auto;
  
  &:after {
    content: attr(data-pct)"%";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 120px;
    text-align: center;
    color: #848ea1;
    font-size: 16px;
  }
  .circle-progress-content {
    transform: rotate(-90deg);
    
    .bar, .bg {
      stroke-linecap: round;
      stroke-width: 5px;
    }
    circle.bar {
      stroke: $primary-color;
      animation: stroke-dashoffset 1s ease-out;
    }
    .bg {
      stroke: #edf0f5;

    }
  }
}

@keyframes stroke-dashoffset {
  from {
    stroke-dashoffset: 0;
  }
}


@media (max-width : 768px){
    .payments__container{
        width: 100%;
        flex-direction: column;
    }

    .payments__card{
        width: 100%;
        margin: 1rem auto;
    }
}