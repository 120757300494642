@import "../../styles/base.scss";

.subscribe--form__container {
  display: flex;
  width: 100%;
  justify-content: space-between;
  position: relative;
  padding: 0px 40px 20px 40px;
  align-items: center;
  background: #fff;
}

.subscribe--form {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  width: 40%;
  padding-top: 20px;

  button {
    font-family: "circular_std_book";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    margin-top: 16px;
    width: 160px;
    height: 50px;
  }

  input {
    background: #f4f4f4;
    width: 80%;
  }

  header {
    h1 {
      font-style: normal;
      font-weight: 500;
      font-size: 42px;
      line-height: 55px;
      /* or 130% */

      color: #000000;
    }
    p {
      font-style: normal;
      font-weight: 450;
      font-size: 18px;
      line-height: 31px;
      /* or 170% */

      /* gray/2 */

      color: #969696;
      padding: 16px 0px;
    }
  }

  .subscribe--form__success,
  .subscribe--form__error {
    padding: 1rem;
  }
  .subscribe--form__success {
    color: rgb(32, 151, 32);
  }
  .subscribe--form__error {
    color: rgb(153, 67, 33);
  }
}

.container--right {
  position: relative;
  padding: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  &::before {
    content: "";
    position: absolute;
    background: url("../../assets/line.svg");
    width: 794.37px;
    height: 632px;
    right: 50px;
  }
  img {
    position: relative;
    z-index: 1;
    border-radius: 8px;
  }

  .contact__call {
    margin-left: 16%;

    .contact__call--text {
      font-size: 28px;
      line-height: 35px;
      margin-bottom: 30px;
    }
    .contact__number {
      font-size: 36px;
      font-weight: bold;
      // line-height: 45px;
      color: white;
      text-decoration: underline;
      cursor: pointer;
    }
  }

  .contact__img {
    position: absolute;
    right: -90px;
    bottom: -90px;
  }
}

@media (max-width: $break-point-xl) {
  .subscribe--form__container {
    flex-direction: column-reverse;
    padding: 34px;
  }
  .subscribe--form {
    width: 100%;
    header {
      h1 {
        font-size: 28px;
        line-height: normal;
      }
    }
  }
  .container--left {
    h1 {
      font-size: 32px;
    }
  }

  .container--right {
    .contact__call {
      // margin: 10% 0 0;
      text-align: center;

      .contact__call--text {
        font-size: 18px;
        line-height: 20px;
      }

      .contact__number {
        font-size: 26px;
        line-height: 20px;
      }
    }

    .contact__img {
      position: relative;
    }
    img {
      width: 100%;
    }
  }
}

@media screen and (max-width: 600px) {
  .subscribe--form__container {
    padding: 0px;
    margin-bottom: 57px;
    width: 100%;
  }
  .subscribe--form {
    width: 100%;
    padding: 0px 16px;
    header {
      width: 343px;

      h1 {
        font-weight: bold;
        font-size: 24px;
        line-height: 29px;
      }
      p {
        font-style: normal;
        font-weight: 450;
        font-size: 14px;
        line-height: 130%;
        color: #969696;
        padding: 5px 0px;
      }
    }
    input {
      background: #f4f4f4;
      height: 56px;
      border: 1px solid #f4f4f4;
      box-sizing: border-box;
      border-radius: 4px;
      width: 100%;
    }
    button {
      width: 100%;
      height: 56px;
    }
  }
  .container--left {
    h1 {
      font-size: 32px;
    }
  }

  .container--right {
    padding: 16px;
    &::before {
      display: none;
    }
    img {
      border-radius: 8px;
    }
  }
}
