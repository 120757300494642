@import "../../../styles/base.scss";

.layout__container {
  display: grid;
}

.layout__container--sidebar,
.layout__container--sidebar-open,
.layout__container--sidebar ul,
.layout__container--sidebar-open ul,
.topnav__links,
.topnav__links ul,
.topnav__links--profile {
  display: flex;
}

.layout__container main {
  position: relative;
}

.layout__container--sidebar,
.layout__container--sidebar-open,
.topnav__links {
  // background-color: #f9fafc;
  background-color: #fff;
}

.topnav__links {
  justify-content: space-between;
  align-items: center;
  // padding: 1rem 1.5rem ;
}

.layout__container--sidebar,
.layout__container--sidebar-open main {
  overflow: scroll;
}

.layout__container {
  height: 100vh;
  max-width: 1920px;
  background: #f2f2f2;
  overflow: hidden;
  grid-template-columns: 20% 1fr;
  main {
    overflow: scroll;
  }
}

.topnav__links {
  position: abs;
  padding: 1rem 2.8rem;
  height: 70px;
  top: 0;
  width: 100%;
  z-index: 90;
  a {
    text-decoration: none;
    color: #828282;
    &:hover {
      color: red;
      transition: ease 0.35s;
    }
  }
}

.topnav__links--container {
  list-style: none;
  align-items: center;
  li {
    margin: 0 1rem;
  }
}

.topnav__links--profile {
  a {
    text-decoration: none;
  }
}

.layout__container--sidebar ul,
.layout__container--sidebar-open ul {
  padding: 0;
  margin: 0;
  list-style: none;

  li {
    font-size: 16px;
    @extend %body-medium;
  }

  a:not(:last-child) {
    color: #828282;
    text-decoration: none;
    margin: 0.5rem 0;
    padding: 0.3rem 1rem;

    &:hover:not(.active) {
      background-color: #f2f2f2;
      transition: background-color ease 0.35s;
    }
  }
}

.active {
  background: #eb2931;

  path {
    fill: #fff;
  }
  li {
    color: #fff;
  }
  border-radius: 10px;
}

.layout__container--sidebar,
.layout__container--sidebar-open,
.layout__container--sidebar-open ul,
.layout__container--sidebar ul {
  flex-direction: column;

  //   border: 1px solid red;
}

.layout__container--sidebar,
.layout__container--sidebar-open {
  align-items: center;
  font-weight: 500;
  height: 100vh;

  padding: 3rem 0 0 0;
}

.logo__container {
  margin-bottom: 4rem;
}

.logout {
  color: #828282;
  background-color: #f2f2f2;
  border-radius: 11.1111px;
  display: inline-block;
  margin-top: 4rem;
  padding: 0.3rem 1rem;
  text-decoration: none;
}

path {
  fill: $label-color;
}

@media screen and (min-width: 900px) {
  .menu__button-close,
  .menu__button-open {
    display: none;
    font-size: 20px;
  }

  .menu__button-close {
    position: absolute;
    z-index: 9999;
    right: 20px;
    top: 2rem;
  }
}

@media screen and (max-width: 900px) {
  .layout__container {
    display: flex;
    main {
      width: 100%;
    }
  }
  .layout__container--sidebar,
  .layout__container--sidebar-open {
    position: absolute;
    transform: translate(-999px, 0);
    transition: ease 0.35s;
  }

  .layout__container--sidebar-open {
    box-shadow: 0px 8px 10px #ccc;
    overflow: scroll;
    position: fixed;
    padding: 2rem 0;
    transform: translate(0, 0);
    // border: 1px solid red;
    width: 250px;
    top: 0;
    z-index: 400;
  }

  .logo__container {
    margin: 1rem 0;
    width: 150px;
    img {
      width: 100%;
      height: 100%;
    }
  }

  .topnav__links {
    flex-direction: column;
    height: 150px;
    width: 100%;

    align-items: flex-start;
    // overflow: hidden;

    .menu {
      display: flex;
      align-items: center;
      gap: 3.5rem;
      h4 {
        margin-top: 0px;
      }
    }

    li {
      margin: 0;
      margin-right: 1rem;
    }
  }

  .topnav__links--container {
    flex-direction: column;
    align-items: inherit;
  }
}

.menu__button-close,
.menu__button-open {
  font-size: 20px;
  cursor: pointer;
}

.menu__button-close {
  position: absolute;
  z-index: 9999;
  right: 20px;
  top: 2rem;
}
