@import "../../../styles/base.scss";

.payments--nav{
    // background-color: red;
    border-bottom: 1px solid rgba(0,0,0,0.2);
    margin-bottom: 2rem;
    nav{
        justify-content:flex-start ;
        padding: 1rem 1rem 0 1rem;
        ul{
            display: flex;
            
            li{
                margin: 1rem 1rem 0 1rem;
                color: #000000;
            }

        }
    }
    
}

.payments--main{
    padding: 1rem 2rem;
}

.active{
    color: $primary-color;
    position: relative;
    &:after{
        content: "";
        position: absolute;
        bottom: -20%;
        width: 100%;
        height: 3px;
        background-color: $primary-color;
    }
}