@import "../../../styles/base.scss";

.signup--container {
  min-height: 100vh;
  background: #11171f;
  // background: #e5e5e5;
  width: 100%;
  // background: url("../../../assets/auth-image.svg") no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5px;
  padding: 5% 0%;

  &__left {
    .login__container--img {
      img {
        width: 180px;
      }
    }
  }
}

@media screen and (max-width: 992px) {
  .signup__container--left {
    display: block;
  }
  .signup__container--aside {
    grid-template-columns: 1fr;

    padding: 1rem;
    width: 100%;
  }
  .signup__container {
    grid-template-columns: 1fr;
  }
}

@media screen and (max-width: 600px) {
  .signup--container {
    width: 100%;
    background-image: none;
    // background: #e5e5e5;
    gap: 2px;
    &__left {
      display: block;
      margin-top: 20px;
      margin-bottom: 30px;
    }
  }
}
