@import "../../../styles/base.scss";

.card__container,
.business__messaging--container {
  display: flex;
}

.business__messaging--container {
  align-items: center;
  flex-direction: column;
  padding: 1rem;
  height: 90%;
  //   border: 1px solid red;
}
.card__container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2rem;

  li {
    h4 {
      color: $primary-color-dark;
      width: 70%;
    }
    font-weight: 400;
    background-color: #fff;
    padding: 1rem;
    // margin: 1rem;
  }
}

@media screen and (max-width: 600px) {
  .card__container {
    grid-template-columns: repeat(1, 1fr);

    li {
      h4 {
        width: 100%;
      }
    }
  }
}
