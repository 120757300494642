@import "../../../../styles/base.scss";

.blog__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2rem;
    z-index: 3;
    position: relative;
    .header__container {
        width: 100%;
        display: grid;
        place-items: center;
        margin: 2rem 0;

        img {
            width: 95%;

            border-radius: 8px;
            aspect-ratio: auto;
            // object-fit: contain;
        }
    }

    .blog__post {
        width: 864.97px;
        .blog--header {
            display: flex;
            flex-direction: column;
            margin-bottom: 4rem;
            .top {
                p {
                    cursor: pointer;
                }
                .post-info {
                    display: flex;
                    align-items: center;
                    gap: 1rem;

                    p {
                        cursor: initial;
                    }
                }
            }

            .author--section {
                margin-top: 2rem;
                display: flex;
                justify-content: space-between;
                .author--info {
                    display: flex;
                    align-items: center;
                    gap: 1rem;

                    img {
                        width: 41px;
                        height: 42.72px;

                        border-radius: 26.5372px;
                    }

                    div {
                        .name {
                            font-style: normal;
                            font-weight: 450;
                            font-size: 20px;
                            line-height: 31px;
                            /* identical to box height, or 153% */

                            letter-spacing: -0.03em;

                            /* gray/1 */

                            color: #323232;

                            transform: matrix(1, 0, 0, 1, 0, 0);
                        }
                        .date {
                            font-style: normal;
                            font-weight: 450;
                            font-size: 16px;
                            line-height: 21px;
                            /* identical to box height, or 130% */

                            /* gray/1 */

                            color: #323232;

                            transform: rotate(-0.16deg);
                        }
                    }
                }

                .author--social {
                    display: flex;
                    flex-direction: column;
                    justify-content: baseline;
                    width: 176px;
                    margin-top: 2rem;
                    p {
                        font-style: normal;
                        font-weight: 450;
                        font-size: 14px;
                        line-height: 18px;
                        /* identical to box height */

                        /* gray/2 */

                        color: #969696;
                    }
                    .social--icons {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        padding: 0px;
                        gap: 40px;

                        width: 176px;
                        height: 32px;
                    }
                }
            }
        }

        .post--body {
            a {
                text-decoration: underline;
            }
            div {
                p {
                    font-style: normal;
                    font-weight: 450;
                    font-size: 20px !important;
                    line-height: 31px;
                    /* or 153% */

                    letter-spacing: -0.03em;

                    /* gray/1 */

                    color: #ebe2e2;

                    transform: matrix(1, 0, 0, 1, 0, 0);
                }
            }
        }
    }

    .suggest--post {
        margin-top: 4rem;
        .post--list {
            display: flex;
            justify-content: space-between;
            gap: 2rem;
            flex-wrap: wrap;
            overflow: hidden;
            width: fit-content;
            &__item {
                width: 427px;
                height: 455.53px;
                margin: 2rem 0;
                cursor: pointer;
                .post--image {
                    width: 427px;
                    height: 300px;
                    border-radius: 8px;
                    img {
                        width: 100%;
                        height: 100%;
                        border-radius: 8px;
                    }
                }

                .post--content {
                    .category {
                        width: 72px;
                        height: 21px;
                        font-style: normal;
                        font-weight: 500;
                        font-size: 12px;
                        line-height: 20px;
                        text-transform: uppercase;
                        color: #000000;
                        padding-top: 10px;
                    }
                    .title {
                        width: 426px;
                        font-style: normal;
                        font-weight: 500;
                        font-size: 24px;
                        line-height: 36px;
                        letter-spacing: -0.04em;
                        text-transform: capitalize;
                        color: #000000;
                        transform: rotate(-0.16deg);
                    }

                    .post--footer {
                        color: #969696;
                        width: 230px;
                        display: flex;
                        justify-content: space-between;
                    }
                }
            }
        }
    }
}

@media screen and (min-width: 350px) and (max-width: 414px) {
    .blog__container {
        padding: 1rem;
        .header__container {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 100%;
        }
        .blog__post {
            width: 100%;
            .blog--header {
                .top {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;
                    width: 100%;
                    margin-bottom: 2rem;
                }

                .author--section {
                    flex-direction: column;
                    .author--info {
                        p {
                            margin: 0;
                        }
                    }
                }
            }
        }

        .suggest--post {
            .post--list {
                justify-content: space-between;
                gap: 0px;
                &__item {
                    height: 331px;
                    width: 48%;
                    margin-bottom: 0px;
                    .post--image {
                        width: 100%;
                        height: 150px;
                    }

                    .post--content {
                        height: 40px;
                        .category {
                            font-size: 0.8rem;
                        }
                        .title {
                            width: 100%;
                            font-size: 1rem;
                            line-height: 1.5rem;
                        }
                    }

                    .post--footer {
                        // width: calc(100% / 2.1);
                        flex-direction: column;
                        justify-content: space-between;
                        p {
                            margin: 0;
                            font-size: 0.8rem;
                        }
                    }
                }
            }
        }
    }
}
