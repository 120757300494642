@import "../../../../styles/base.scss";

.changepassword__container--form {
  display: flex;
  max-width: 50%;
  button {
    margin: 2rem 0;
  }
}

.form__group {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;

  .toggle__password {
    position: absolute;
    top: 55px;
    right: 5%;
    cursor: pointer;
    transition: 0.1s ease-in-out;

    img {
      width: 20px;
      height: 20px;
    }
  }
}
