.privacy {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px 0px;
    position: relative;
    z-index: 3;
    overflow: hidden;

    &::before {
        content: "";
        position: absolute;
        width: 560.67px;
        height: 560.67px;
        left: -82px;
        top: 2135px;

        background: #fff3db;
        filter: blur(400px);
    }

    &::after {
        content: "";
        position: absolute;
        width: 560.67px;
        height: 560.67px;
        left: 960.59px;
        top: 2415.33px;

        background: #f1faf8;
        filter: blur(400px);
    }
    .container {
        width: 870px;

        h3 {
            font-style: normal;
            font-weight: 500;
            font-size: 56px;
            line-height: 74px;
            text-align: center;
            padding-bottom: 30px;
            color: #000000;
        }

        .title {
            font-style: normal;
            font-weight: 600;
            font-size: 32px;
            line-height: 42px;
            /* identical to box height, or 130% */

            color: #000000;
        }
        .subtitle {
            font-style: normal;
            font-weight: 600;
            font-size: 24px;
            line-height: 36px;
            /* identical to box height, or 150% */

            color: #000000;
        }

        .text {
            font-style: normal;
            font-weight: 450;
            font-size: 18px;
            line-height: 31px;
            padding: 10px 0px;
            color: #000000;
            text-align: justify;
        }

        ul {
            list-style: disc;
            margin-left: 20px;
            li {
                font-style: normal;
                font-weight: 450;
                font-size: 18px;
                line-height: 31px;
                padding: 5px 0px;
                color: #000000;
            }
        }
    }
}

@media screen and (max-width: 600px) {
    .privacy {
        .container {
            width: 343px;

            h3 {
                font-size: 38px;
            }

            .title {
                font-size: 22px;
                line-height: 32px;
            }

            .subtitle {
                font-size: 20px;
                line-height: 28px;
            }

            .text {
                font-size: 14px;
                line-height: 24px;
            }

            ul {
                li {
                    font-size: 14px;
                    line-height: 24px;
                }
            }
        }
    }
}
