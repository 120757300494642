@import "../../../styles/base.scss";

.wallets--nav {
  // background-color: red;

  justify-content: flex-start;
  padding: 1rem;

  ul {
    display: flex;

    li {
      margin: 1rem;
    }
  }
}

.wallets--main {
  align-items: flex-start;
  display: flex;
  justify-content: space-between;

  padding: 1rem 2rem;
}

.active {
  color: $primary-color;
  border-bottom: 3px solid red;
}
