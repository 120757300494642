@import "../../../styles/base.scss";

.top {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: flex-start;

  h2 {
    font-size: 30px;
    text-align: left;
  }
  // text-align: left;
  p {
    color: $text-light;
    font-size: 18px;
  }
}

form {
  margin-top: 15px;
  label {
    display: block;
  }

  input {
    width: 100%;
  }

  button {
    margin-top: 30px;
  }
}

.dashboard__info,
.main__wrapper {
  display: grid;
  grid-template-columns: 300px 1fr;
  gap: 2rem;
  padding: 3rem;
}

.dashboard__info--card,
.dashboard__credithistory--card,
.dashboard__userguide--card {
  border-radius: 1rem;
  border: 1.11111px solid #cfe3ff;
}

.dashboard__info {
  grid-template-columns: repeat(4, minmax(0, 1fr));
  grid-gap: 1rem;
  grid-column: 1/-1;
  padding: 1rem 0;
}

.dashboard__credithistory--card,
.dashboard__info--card,
.dashboard__userguide--card {
  background-color: #fff;
  padding: 1rem;
}

.dashboard__info--actionarea,
.dashboard__info--card header,
.dashboard__credithistory--card {
  display: flex;
}

.dashboard__credithistory {
  height: 600px;
  overflow: scroll;
  header {
    background-color: #f2f2f2;
    position: sticky;
    // padding: 1rem;
    top: -0.3rem;
  }
  h3 {
    color: $text-light;
  }
}

.dashboard__info--card {
  // height: 150px;
  h2,
  span:not(:first-of-type) {
    color: $primary-color-dark;
  }
  // h2 {
  //   margin-right: 1rem;
  // }

  span {
    // font-size: 18px;
    margin: 0 1rem;
  }
  header {
    align-items: baseline;
    margin: 0 0 1rem 0;
  }
}

.dashboard__credithistory--card {
  margin: 1rem 0;
  justify-content: space-between;

  span,
  h4 {
    font-size: 20px;
  }
  h4 {
    @extend %body-normal;
    margin-top: 0;
    color: $text-dark;
  }
  span {
    color: $primary-color;
  }
  p {
    color: $text-light;
  }
}

.dashboard__info--actionarea {
  flex-direction: column;
  justify-content: space-around;
  button {
    // height: 30px;
    font-size: 16px;
    width: 100%;
    padding: 0;

    span {
      display: inline;
    }
  }
  // border: 1px solid red;
}

.dashboard__credithistory,
.dashboard__userguide {
  header {
    color: $primary-color-dark;
  }
}

.dashboard__credithistory--card,
.dashboard__userguide--card {
  h4,
  span,
  a {
    color: $primary-color;
  }
}

.dashboard__userguide {
  header {
    margin: 0 0 1rem 0;
  }
}

.dashboard__userguide--card {
  position: relative;
  h4 {
    @extend %body-medium;
  }

  .heading2 {
    width: 80%;
  }
  .heading3 {
    width: 60%;
  }

  a {
    font-size: 20px;
  }
  img {
    position: absolute;
    top: calc(100vh - 35rem);
    right: -18px;
  }
}

@media screen and (max-width: 900px) {
  .dashboard__info,
  .main__wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
    padding: 1rem;
  }

  .dashboard__info {
    & + div {
      grid-column: 1/-1;
      border: 1px solid red;
      padding: 0;
    }
  }

  .dashboard__info--header {
    grid-column: 1/-1;
  }

  .dashboard__userguide--card {
    order: 1;
    .heading2 {
      width: 100%;
    }
    .heading3 {
      width: 100%;
    }
    img {
      display: none;
      position: absolute;
      // right: -18px;
    }
  }
}
@media screen and (max-width: 600px) {
  .dashboard__info,
  .main__wrapper {
    display: grid;
    grid-template-columns: 1fr;
    gap: 2rem;
    padding: 1rem;
  }

  .dashboard__userguide--card {
    .heading2 {
      width: 100%;
    }
    .heading3 {
      width: 100%;
    }
    order: 1;
    img {
      display: none;
      position: absolute;
      // right: -18px;
    }
  }
}
