@import "../Form-row/index.module.scss";

.file__upload--container {
    height: 220px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2rem;
    border-radius: $border-radius;
    border: 2.5px dashed rgba(0, 0, 0, 0.2);
    margin-bottom: 45px;
    p:last-child {
        color: gray;
    }
    img {
        margin: 0.1rem auto;
    }
    input {
        display: none;
        opacity: 0;
        overflow: hidden;
        position: absolute;
        z-index: -1;
    }
    input:focus + label {
        outline: -webkit-focus-ring-color auto 5px;
    }
    input + label * {
        pointer-events: none;
    }
    label {
        border: 1px solid rgba(0, 0, 0, 0.3);
        padding: 0.5rem;
        cursor: pointer;
    }

    .filename {
        color: $primary-color;
        // &:after{
        //     // content: "\2713";
        //     margin-inline-end: .5rem;
        //     border: 1px solid $primary-color;
        //     padding: .3rem;
        //     border-radius: 50%;
        // }
    }
}

.upload__evidence--img-container {
    // border: 1px solid red;
    // width: 200px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    label {
        display: inline-block;
        // margin-top: 1rem;
    }
}

@media screen and (max-width: 600px) {
    .file__upload--container {
        label {
            width: 260px;
        }
    }
}

@media screen and (min-width: 1024px) and (max-width: 1440px) {
    .file__upload--container {
        height: 180px !important;
        padding: 1rem !important;
        p {
            margin: 0px;
            padding: 0px;
            font-size: 14px !important;
        }

        label {
            display: inline-block;
        }
    }
}
