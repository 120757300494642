@import "../../../styles/base.scss";

.header__details {
    display: grid;
    place-items: center;
    width: 100%;
    .tabs {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 1rem;
        flex-direction: row;
        width: 574px;
        height: 45px;
        margin-top: 75px;

        .tab {
            width: 91px;
            height: 45px;

            border: 1px solid #d8d8d8;
            border-radius: 200px;
            display: grid;
            place-items: center;
            cursor: pointer;
        }
        .tab:nth-child(2) {
            width: 137px;
        }

        .active {
            border: 1px solid #eb2931;
        }
    }
}

.container {
    padding: 3rem;
    position: relative;
    z-index: 3;
    margin-bottom: 6rem;
    overflow: hidden;
    .recent--post {
        width: 100%;
        &__container {
            padding: 2rem 0;
            display: flex;
            gap: 2rem;
            align-items: center;

            img {
                height: 648px;
                border-radius: 16px;
            }

            .post__content {
                height: 331.55px;
                cursor: pointer;
                .category {
                    font-style: normal;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 20px;
                    /* identical to box height, or 146% */

                    text-transform: uppercase;

                    color: #000000;
                }
                h3 {
                    font-style: normal;
                    font-weight: 500;
                    font-size: 42px;
                    line-height: 55px;
                    /* or 130% */

                    letter-spacing: -0.03em;

                    color: #000000;
                }
                p {
                    font-style: normal;
                    font-weight: 450;
                    font-size: 20px !important;
                    line-height: 31px;
                    /* or 153% */

                    letter-spacing: -0.03em;

                    /* gray/2 */

                    color: #969696;

                    transform: matrix(1, 0, 0, 1, 0, 0);
                }
                .post-author {
                    display: flex;
                    gap: 20px;
                    align-items: center;
                    img {
                        width: 41px;
                        height: 42.72px;

                        border-radius: 26.5372px;
                    }

                    p {
                        font-style: normal;
                        font-weight: 450;
                        font-size: 20px;
                        line-height: 31px;
                        letter-spacing: -0.03em;
                        color: #323232;
                        transform: matrix(1, 0, 0, 1, 0, 0);
                    }
                }
            }
        }
    }
    .post__header {
        margin-top: 4rem;
        width: 100%;
        display: flex;
        justify-content: space-between;

        .sort {
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 10px;
            position: relative;
            p {
                font-style: normal;
                font-weight: 450;
                font-size: 14px;
                line-height: 24px;
                /* identical to box height, or 170% */

                letter-spacing: -0.02em;

                /* grey/3 */

                color: #64748b;
            }

            .filter {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                padding: 8px 14px;
                gap: 10px;
                cursor: pointer;
                width: auto;
                height: 42px;

                background: #ffffff;
                border: 0.5px solid #969696;
                border-radius: 4px;

                p {
                    font-style: normal;
                    font-weight: 450;
                    font-size: 14px;
                    line-height: 24px;
                    /* identical to box height, or 170% */

                    letter-spacing: -0.02em;

                    /* grey/1 */

                    color: #0f172a;
                }
                img {
                    width: 9px;
                    height: 5px;
                }
            }
        }
    }

    .post--list {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        overflow: hidden;
        width: fit-content;
        gap: 3rem;
        &__item {
            width: 495px;
            height: 455.53px;
            margin: 2rem 0;
            cursor: pointer;
            .post--image {
                width: 100%;
                height: 300px;
                border-radius: 8px;
                img {
                    width: 100%;
                    height: 100%;
                    border-radius: 8px;
                }
            }

            .post--content {
                .category {
                    width: 72px;
                    height: 21px;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 12px;
                    line-height: 20px;
                    text-transform: uppercase;
                    color: #000000;
                    padding-top: 10px;
                }
                .title {
                    width: 486px;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 24px;
                    line-height: 36px;
                    letter-spacing: -0.04em;
                    text-transform: capitalize;
                    color: #000000;
                    transform: rotate(-0.16deg);
                }

                .post--footer {
                    color: #969696;
                    width: 230px;
                    display: flex;
                    justify-content: space-between;
                }
            }
        }
    }

    .blog--pagination {
        div {
            float: right;
        }
    }
}

.not-found {
    height: 532px;
    left: 64px;
    top: 940px;
    width: 100%;
    background: #f8fafc;
    border-radius: 8px;
    margin: 35px 0px;

    display: flex;
    justify-content: center;
    align-items: center;

    .not-found__content {
        width: 364px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        h3 {
            font-style: normal;
            font-weight: 500;
            font-size: 24px;
            line-height: 36px;
            /* or 150% */

            text-align: center;

            /* neutral / black */

            color: #000000;
            text-align: center;
        }
    }
}

.dropdown {
    position: absolute;
    top: 28px;
    left: 55px;
    width: 120px;
    z-index: 3;
    background: #fff;
    border-radius: 0 0 4px 4px;
    padding: 2px 10px;

    .actions {
        position: relative;
        display: flex;
        flex-direction: column;

        .action {
            cursor: pointer;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            border-bottom: 1px solid rgba(0, 0, 0, 0.1);

            span {
                font-style: normal;
                font-weight: normal;
                font-size: 16px;
                line-height: 20px;
            }
        }

        .action:last-child {
            border-bottom: none;
        }
    }
}

@media screen and (max-width: 1440px) {
    .container {
        margin-top: 3rem;

        .post__header {
            .sort {
                justify-content: start;
                .filter {
                    width: 120px;
                }
            }
        }
        .post--list {
            gap: 2.8rem;
            overflow: unset;
            width: fit-content;
            justify-content: flex-start;
            width: 100%;
            &__item {
                height: 420px;
                width: 31%;

                .post--image {
                    height: 270px;
                    width: 100%;
                }

                .post--content {
                    .title {
                        width: 427px;
                        text-align: left;
                    }
                }
            }
        }
    }
}

@media screen and (width: 1920px) {
    .container {
        margin-top: 3rem;

        .post__header {
            .sort {
                justify-content: start;
                .filter {
                    width: 120px;
                }
            }
        }
        .post--list {
            justify-content: initial;
            width: 100%;
            &__item {
                height: auto;
                width: 32%;

                .post--image {
                    width: 100%;
                    height: 380px;
                }
            }
        }
    }
}

@media screen and (min-width: 350px) and (max-width: 414px) {
    .header__details {
        .tabs {
            width: 343px;
            flex-wrap: wrap;
            justify-content: space-evenly;
            align-items: center;

            .tab {
                margin-bottom: 10px;
            }
        }
    }

    .container {
        padding: 1rem;
        position: relative;
        // top: -80px;

        .recent--post {
            &__container {
                flex-direction: column;
                width: 100%;

                img {
                    height: 250px;
                }

                .post__content {
                    height: auto;

                    h3 {
                        font-size: 28px;
                        line-height: 32px;
                    }
                    p {
                        font-size: 12px;
                    }
                }
            }
        }
        .post__header {
            align-items: center;
            h3 {
                font-size: 1rem;
                margin: 0;
            }

            p {
                font-size: 0.8rem;
                margin: 0;
            }

            .sort {
                .filter {
                    width: 120px;
                    height: 32px;
                }
            }
        }

        .post--list {
            justify-content: space-between;
            gap: 0px;
            &__item {
                height: 311px;
                width: 48%;
                margin-bottom: 0px;
                .post--image {
                    width: 100%;
                    height: 150px;
                }

                .post--content {
                    height: 40px;
                    .category {
                        font-size: 0.8rem;
                    }
                    .title {
                        width: 100%;
                        font-size: 1rem;
                        line-height: 1.5rem;
                    }
                }

                .post--footer {
                    // width: calc(100% / 2.1);
                    flex-direction: column;
                    justify-content: space-between;
                    p {
                        margin: 0;
                        font-size: 0.8rem;
                    }
                }
            }
        }
    }
}

@media screen and (min-width: 1024px) and (max-width: 1300px) {
    .container {
        .recent--post {
            &__container {
                img {
                    height: 350px;
                }

                .post__content {
                    height: auto;

                    h3 {
                        font-size: 18px;
                        line-height: 24px;
                    }
                    p {
                    }
                }
            }
        }
        .post__header {
            align-items: center;
            h3 {
                font-size: 1rem;
                margin: 0;
            }

            p {
                font-size: 0.8rem;
                margin: 0;
            }

            .sort {
                .filter {
                    width: 120px;
                    height: 32px;
                }
            }
        }

        .post--list {
            justify-content: initial;
            width: 100%;
            &__item {
                width: 30%;
                height: auto;
                .post--image {
                    width: 100%;
                    height: 210px;
                }

                .post--content {
                    .category {
                        font-size: 0.8rem;
                    }
                    .title {
                        width: 100%;
                        font-size: 1rem;
                    }
                }

                .post--footer {
                    // width: calc(100% / 2.1);
                    flex-direction: column;
                    justify-content: space-between;
                    p {
                        margin: 0;
                        font-size: 0.8rem;
                    }
                }
            }
        }
    }
}

@media screen and (width: 768px) {
    .container {
        .recent--post {
            &__container {
                flex-direction: column;
            }
        }

        .post--list {
            width: 100%;
            flex-direction: column;
            &__item {
                width: 100%;
                height: auto;
                .post--image {
                    width: 100%;
                    height: 370px;
                }
                .post--content {
                    .title {
                        width: 100%;
                    }
                }
            }
        }
    }
}

// @media screen and (width: 320px) {
//     .header__details {
//         .tabs {
//             width: 100%;
//             flex-wrap: wrap;
//             justify-content: space-evenly;
//             align-items: center;

//             .tab {
//                 margin-bottom: 10px;
//             }
//         }
//     }
//     .container {
//         .recent--post {
//             &__container {
//                 flex-direction: column;
//                 width: 100%;
//                 align-items: baseline;

//                 img {
//                     height: auto;
//                     width: 100%;
//                 }
//             }
//         }

//         .post--list {
//             // width: 100%;
//             flex-direction: column;
//             &__item {
//                 // width: 100%;
//                 height: auto;
//                 .post--image {
//                     // width: 100%;
//                     height: auto;
//                 }
//                 .post--content {
//                     .title {
//                         width: 100%;
//                     }
//                 }
//             }
//         }
//     }
// }
