@import "../../../styles/base.scss";

.cards--container,
.cards--item {
    display: flex;
}

.cards--container {
    margin-top: 2rem;
    margin-bottom: 2rem;
}

.cards--item {
    background-color: #fff;
    padding: 1.5rem;
}

.cards--item {
    align-items: flex-start;
    border-radius: 1rem;
    margin-right: 3rem;
    width: 250px;
    box-shadow: 0px 8px 10px #ccc;

    h2 {
        color: $primary-color-dark;
        margin: 0;
    }

    p {
        color: $text-light;
    }
}

.table--top {
    display: flex;
    width: 1212px;
    justify-content: space-between;
    p {
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 23px;
        letter-spacing: 0.2px;

        color: #eb2931;
    }
}

.search--bar {
    position: relative;
    margin-right: 2rem;
    input[type="text"] {
        background: #ffffff;
        border-radius: 8px;
        border: none;
        width: 324px;
        height: 56px;
        padding-left: 2.5rem;
        font-size: 18px;
        line-height: 23px;
        letter-spacing: 0.2px;

        color: #c4c4c4;
    }
    span {
        position: absolute;
        left: 10px;
        top: 10px;
        margin-right: 30px;
    }
}

.filter--section {
    display: flex;
    justify-content: space-between;
}

.table--container {
    width: 1212px;
    height: auto;
    margin-top: 20px;
    background: #ffffff;
    box-shadow: 0px 15px 30px rgba(123, 123, 123, 0.2);
    border-radius: 10px;
    padding: 1rem;
}

table {
    border-spacing: 1rem;
    // border-collapse: separate;
}
tbody .table--body {
    background: transparent;
    box-shadow: 0px 2px 20px 3px rgba(123, 123, 123, 0.25);
    border-radius: 7px;
    margin-top: 40px;
}

td:first-child {
    border-top-left-radius: 7px;
    border-bottom-left-radius: 7px;
}
th:first-child {
    border-top-left-radius: 7px;
    border-bottom-left-radius: 7px;
}
td:last-child {
    border-top-right-radius: 7px;
    border-bottom-right-radius: 7px;
}

th:last-child {
    border-top-right-radius: 7px;
    border-bottom-right-radius: 7px;
}

.status--confirmed,
.status--pending,
.status--new {
    // font-size: 15px;
    // padding: 7px 23px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 15px;
}
.status--confirmed {
    color: #219681;
    background: rgba(61, 255, 92, 0.2);
    border: 2px solid rgba(9, 100, 7, 0.2);
}

.status--pending {
    color: #eb2931;
    background: rgba(235, 41, 49, 0.2);
    border: 2px solid #eb2931;
}

.status--new {
    color: rgba(21, 101, 216, 0.7);
    background: rgba(207, 227, 255, 0.5);
    border: 2px solid #1565d8;
}

.action--dialog {
    position: relative;
    width: 212px;
    height: 167px;
    background: #ffffff;
    border: 0.1px solid #000000;
    box-sizing: border-box;
    box-shadow: 0px 15px 30px rgba(123, 123, 123, 0.2);
    border-radius: 8px;
    padding: 0 2rem;
    position: absolute;
    right: 150px;

    .delete--action {
        font-weight: 500;
        font-size: 24px;
        line-height: 29px;
        display: flex;
        align-items: baseline;
        text-align: center;
        color: #eb2931;
        cursor: pointer;
    }

    .approved--action {
        font-weight: 500;
        font-size: 24px;
        line-height: 29px;
        display: flex;
        align-items: baseline;
        text-align: center;
        color: #219681;
        cursor: pointer;
    }

    .reject--action {
        font-weight: 500;
        font-size: 24px;
        line-height: 29px;
        display: flex;
        align-items: baseline;
        text-align: center;
        color: #fed555;
        cursor: pointer;
    }

    .divider {
        width: 209px;
        position: absolute;
        right: 0;
        left: 0;
        height: 0px;
        top: 40px;
        margin-bottom: 10px;
        margin-right: 20px;
        border: 1px solid #8692a6;
    }

    .second-divider {
        width: 209px;
        position: absolute;
        right: 0;
        left: 0;
        height: 0px;
        bottom: 50px;
        margin-bottom: 10px;
        margin-right: 20px;
        border: 1px solid #8692a6;
    }
}

.delete {
    width: 212px;
    height: 64px;
    right: 175px;

    position: absolute;
    background: #ffffff;
    box-shadow: 0px 15px 30px rgba(123, 123, 123, 0.2);
    .delete--action {
        font-weight: 500;
        margin-left: 40px;
        font-size: 24px;
        line-height: 29px;
        display: flex;
        align-items: baseline;
        text-align: center;
        color: #eb2931;
        cursor: pointer;
    }
}

.paginate {
    width: 100%;
}

.deleteModal {
    width: 366px;
    height: 189px;

    background: #ffffff;
    border-radius: 20px;
    .buttons {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        margin-top: 2rem;
    }
    p {
        font-style: normal;
        font-weight: bold;
        font-size: 30px;
        line-height: 36px;
        display: flex;
        align-items: center;

        color: #000000;
    }
}

.confirm {
    width: 516px;
    height: 189px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #ffffff;
    border-radius: 20px;

    p {
        font-style: normal;
        font-weight: bold;
        font-size: 30px;
        line-height: 36px;
        align-items: center;
        text-align: center;
        color: #000000;
        margin-bottom: 2rem;
    }
}
