@import "../../../../styles/base.scss";

.header__details {
    margin-top: 3rem;
    width: 100%;

    .header {
        text-align: left;
        margin-bottom: 10%;
        display: flex;
        // justify-content: space-between;
        position: relative;
        overflow: hidden;
        width: 100%;
        .column {
            width: 100%;
            height: 580px;
            position: relative;

            video {
                position: absolute;
                bottom: 100px;
                left: 0;
                width: 100%;
                height: 100%;
            }
        }
        h3 {
            margin-bottom: 6%;
            margin-top: 0px;
            font-size: 2.8rem;
            font-weight: bold;
            line-height: 4rem;

            span {
                margin-bottom: 6%;
                font-size: 2.8rem;
                font-weight: bold;
                line-height: 4rem;
                color: $primary-color;
            }
        }
    }

    div {
        // margin-top: 120px;
        text-align: left;
        margin-bottom: 10%;
        margin-right: 100px;
        h3 {
            margin-bottom: 6%;
            font-size: 2.8rem;
            font-weight: bold;
            line-height: 4rem;

            span {
                margin-bottom: 6%;
                font-size: 2.8rem;
                font-weight: bold;
                line-height: 4rem;
                color: $primary-color;
            }
        }
    }

    img {
        margin-top: -50px;
        width: 50%;
        height: 50%;
    }
}

.form-section {
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    input {
        margin-bottom: 24px;
    }

    .small--filled {
        color: #fff;
        cursor: pointer;
        border: none;
        background-color: $primary-color;
        border-radius: 6px;
        padding: 12px 24px;
        font-size: 14px;
        width: 262px;
        font-size: 20px;
        font-weight: 500;
        height: 54px;
        text-align: center;
    }
}

.dialg--container {
    width: 548px;
    display: flex;
    flex-direction: column;
    padding: 20px;
    min-height: 70vh;
    max-height: 90vh;
    overflow: auto;
    .close-dialog {
        background: #eeeeee;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        right: 59px;
        cursor: pointer;
    }
}
.section__form__three {
    .form-head {
        display: flex;
        justify-content: space-between;
        align-items: center;

        span {
            font-style: normal;
            font-weight: 500;
            font-size: 18px;
            line-height: 22px;
            /* identical to box height */

            display: flex;
            align-items: center;

            color: #8692a6;
        }
    }
    .form__btn__group {
        padding: 0.25rem 0;
    }
}
.response {
    padding-top: 64px;
    padding-bottom: 24px;
    p {
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 23px;
        padding-top: 16px;
    }
}

@media screen and (max-width: 600px) {
    .header__details {
        width: 343px;
        .header {
            margin-bottom: 0%;
            width: 343px;
            flex-direction: column-reverse;
            // gap: 2rem;
            overflow: inherit;
            .column {
                width: 333px;
                height: 290px;
                video {
                    bottom: 20px;
                }
            }
            h3 {
                font-style: normal;
                font-weight: bold;
                font-size: 30px;
                line-height: 42px;
                letter-spacing: -1px;
                span {
                    font-style: normal;
                    font-weight: bold;
                    font-size: 32px;
                    line-height: 42px;
                    letter-spacing: -1px;
                }
            }

            p {
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                line-height: 18px;
            }
        }
    }

    .form-section {
        .small--filled {
            width: 100%;
        }
    }

    .dialg--container {
        height: 549px;
        min-height: 70vh;
        max-height: 90vh;

        img {
            display: flex;
            margin: 0 auto;
        }
        .response {
            h3 {
                font-style: normal;
                font-weight: bold;
                font-size: 24px;
                line-height: 31px;
                text-align: center;

                color: #000000;
            }
            p {
                text-align: center;
                font-size: 14px;
                line-height: 18px;
                color: #8692a6;
            }
        }
    }

    .start-button-container {
        .start-button,
        .stop-button {
            width: 285px;
            height: 64px;
        }
    }
}

@media (max-width: $break-point-xl) {
    .header__details {
        flex-direction: column;
        height: 100%;
        padding-top: 10%;

        div {
            margin-bottom: 40px;
            margin-right: 0;
            margin-top: 0;

            h3 {
                font-size: 1.5rem;
                line-height: 2rem;
            }
        }

        img {
            margin-top: -100px;
            height: 100%;
            width: 100%;
        }
    }
    .dialg--container {
        width: 100%;
        padding: 0;
    }

    .section__one,
    .section__two,
    .section__three {
        padding: 15% 5%;
    }

    .form__btn__group,
    .section__form__one {
        flex-direction: column;
    }

    .section__form__three,
    .form__btn {
        width: 100%;
    }

    .section__title__box {
        .section__title {
            font-size: 1.5rem;
            line-height: 1.5rem;
        }
        .section__subtitle {
            width: 95%;
        }
    }

    .component__right {
        margin-right: 0;
        margin-bottom: 5%;
    }
    .component__left {
        margin-left: 0;
    }
}
