.upload__evidence--container{
    background-color: #fff;
    padding: 1.5rem 2rem;
    width: 60%;
}

@media screen and (max-width:900px){
    .upload__evidence--container{
        width: 100%;
    }
}