@import "../../../../styles/base.scss";

.personaldetailsform__container--form,
.businessinfo__container--form,
.form__container--row {
  display: flex;
  margin: 0.5rem 0;
}



hr {
  display: block;
  clear: both;
  border: 0.5px solid rgb(165, 165, 165);
  height: 0.1px;
  margin: 1rem 0;
}

.form__seperator {
  clear: both;
  display: block;
  width: 100%;
  height: 0.5px;
  margin: 2rem 0rem;
}

form {
  width: 100%;
}

.form__container--row {
  flex-direction: row;
  justify-content: space-between;

  & > div {
    width: 48%;
  }


}

@media (max-width: 768px) {
  .form__container--row {
    flex-direction: column;

    & > div {
      width: 100%;
    }
  }
}
