@import "../Form-row/index.module.scss";

.file__upload--container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2rem;
    border-radius: $border-radius;
    border: 2.5px dashed rgba(0,0,0,0.2);
    margin-bottom: 10px;
    img{
        margin: .8rem auto;
    }
    input{
        display: none;
        opacity: 0;
        overflow: hidden;
        position: absolute;
        z-index: -1;
      }
    input:focus + label {
        outline: -webkit-focus-ring-color auto 5px;
    }
    input + label * {
        pointer-events: none;
    }
    label{
        border: 1px solid rgba(0,0,0,0.3);
        padding: .5rem 1.5rem;
        cursor: pointer;
    }

    .filename{
        color: $primary-color;
        // &:after{
        //     // content: "\2713";
        //     margin-inline-end: .5rem;
        //     border: 1px solid $primary-color;
        //     padding: .3rem;
        //     border-radius: 50%;
        // }
    }
}


.upload__evidence--img-container{
    // border: 1px solid red;
    width: 500px;

    label{
        display: inline-block;
        margin-top: 3rem;

    }
}

