@import "../../../../styles/base.scss";

.wallet--atmDisplay,
.wallet--plans,
.bankPayment--container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.wallet--atmDisplay,
.bankPayment--info {
  padding: 1rem;
}

.wallet--atmDisplay {
  background: rgba(252, 193, 142, 0.274);
  border-radius: 15px;
  margin-top: 3rem;
  height: 250px;
  overflow: hidden;

  position: relative;

  button {
    width: 30%;
    border: 1px solid #000;
    color: #000;
    position: relative;
    left: 14rem;
  }

  p {
    color: #eb5757;
  }

  &::before,
  &:after {
    content: "";
    height: 200px;
    width: 600px;
    position: absolute;
    border-radius: 50%;
    background-color: #eb57571a;
  }

  &:before {
    right: 4rem;
    top: 7rem;
    transform: rotate(10deg);
    pointer-events: none;
  }

  &:after {
    left: 14rem;
    bottom: 8rem;
    transform: rotate(5deg);
  }

  label {
    font-weight: 300;
    color: 828282;
  }

  a {
    color: #000;
    font-weight: 600;
    margin-left: 2rem;
    &:visited {
      color: #000;
    }
  }
}

.wallet--pricing {
  header {
    text-align: center;
    margin: 1rem 0;
  }
  padding: 1rem 3rem;
  table {
    border-collapse: collapse;
    width: 100%;
    th,
    td {
      padding: 1rem;
    }
    td {
      text-align: center;
    }
  }
  button {
    margin: 1.5rem 0 0 0;
  }
}

.credit--misc {
  display: grid;
  grid-template-columns: 30% 1fr;
  gap: 1rem;
  margin-top: 3rem;
}

.wallet--plans,
.wallet--card-info {
  background-color: #fff;
}

.wallet--card-info {
  // border: 1px solid #ccc;
  // box-shadow: 0px 8px 10px #ccc;
  background-color: #fff;
  padding: 1.5rem 2rem;
  width: 600px;
}

.wallet--inputs {
  input {
    margin: 1rem 0;
  }
}

.wallet--plans {
  height: 400px;
  padding: 3rem 2rem;
  position: relative;
  right: -7rem;
  top: -4rem;
  width: 80%;
  h3 {
    color: $primary-color;
  }
  p {
    width: 50%;
  }
}

.wallet--plans-illustration {
  position: absolute;
  top: 8rem;
  left: 15rem;
}

.pricing--links {
  color: #eb5757;
  border-bottom: 1px solid red;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.bankPayment--container {
  // border: 1px solid red;
  align-items: center;
  height: 320px;
  hr {
    width: 30%;
  }
}

.bankPayment--info {
  border: 1px solid #ccc;
  border-radius: 15px;
  // height: 97.01px;
  text-align: center;
}

.payment__method--info {
  .fa-icon{
    color: red;
  }

  p {
    box-shadow: 0px 8px 10px #ccc;
    font-size: 14px;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    transform: translate(0, -30px);
    width: 300px;
  }
  &:hover {
    p {
      // border: 1px solid red;
      background: #fafafc;
      font-weight: normal;
      opacity: 1;
      padding: 1rem;

      transform: translate(0, 3px);
      top: 3rem;
      left: 2rem;
      transition: transform ease 0.35s;
    }
  }
}
