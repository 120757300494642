@import "../../../styles/base.scss";

.container {
    display: flex;
    justify-content: center;
    align-items: center;

    .forgot--password {
        width: 613px;
        padding: 20px 40px;
        background: #ffffff;
        border-radius: 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        form {
            label {
                font-style: normal;
                font-weight: 600;
                font-size: 14px;
                line-height: 31px;
                /* identical to box height, or 219% */

                color: #000000;
            }
            button {
                font-family: "circular_std_book";
                font-weight: 600;
                font-size: 18px;
                line-height: 24px;
            }
        }

        h3 {
            font-style: normal;
            font-weight: 700;
            font-size: 24px;
            line-height: 36px;
            text-align: center;
            color: #000000;
        }

        p {
            font-style: normal;
            font-weight: 450;
            font-size: 14px;
            line-height: 18px;
            text-align: center;
            width: 369px;
            color: #969696;
        }

        .bottom--link {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            p {
                margin-top: 20px;
                font-style: normal;
                font-weight: 450;
                font-size: 18px;
                line-height: 31px;
                color: #000000;
            }
        }
    }
}

.sent {
    width: 613px;
    padding: 20px 40px;
    background: #ffffff;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    h3 {
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 36px;
        text-align: center;
        color: #000000;
    }

    p {
        font-style: normal;
        font-weight: 450;
        font-size: 14px;
        line-height: 18px;
        text-align: center;
        width: 369px;
        color: #969696;
        padding: 10px 0px;
    }

    button {
        font-family: "circular_std_book";
        font-weight: 600;
        font-size: 18px;
        line-height: 24px;
    }
}

@media screen and (max-width: 600px) {
    .container {
        .forgot--password {
            width: 334.77px;
            border-radius: 10px;
            padding: 20px;

            h3 {
                font-style: normal;
                font-weight: 500;
                font-size: 24px;
                line-height: 30px;
                /* or 125% */

                color: #000000;
            }

            p {
                font-style: normal;
                font-weight: 450;
                font-size: 14px;
                line-height: 17px;
                width: 291.63px;
                text-align: left;

                color: #969696;
            }

            form {
                label {
                    font-style: normal;
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 17px;
                    /* identical to box height, or 120% */

                    color: #000000;
                }
            }
        }
    }

    .sent {
        width: 334.77px;
        border-radius: 10px;
        padding: 20px;

        h3 {
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 30px;
            /* or 125% */

            color: #000000;
        }

        p {
            font-style: normal;
            font-weight: 450;
            font-size: 14px;
            line-height: 17px;
            width: 291.63px;
            text-align: left;
            padding: 10px 0px;
            color: #969696;
        }
    }
}
