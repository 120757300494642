@import "../../../../styles/base.scss";

.cards--container,
.HistoryTable--container header,
.cards--item {
  display: flex;
  // justify-content: space-evenly;
}

.cards--container {
  margin-bottom: 2rem;
}

.HistoryTable--container,
.cards--item {
  background-color: #fff;
  padding: 1.5rem;
}

.cards--item {
  align-items: flex-start;
  border-radius: 1rem;
  margin-right: 1rem;
  width: 250px;
    // border: 1px solid $border-color ;
  box-shadow: 0px 8px 10px #ccc;

  span {
    margin-right: 0.5rem;
  }
  h2 {
    margin: 0;
  }
}

.HistoryTable--container {
  header {
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1rem;
  }

  table {
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
    *{
      text-align: left;
    }
    td,th {
      padding: 1rem;
      text-align: center;
      vertical-align: middle;
      // border: 1px solid red;

    }
  }
}

.table-row {
  border-top: 1em solid transparent;
  box-shadow: 0px 2px 5px #ccc;
}

.filter__inputs{
  select{
    width: fit-content;
    padding: 1.5rem;
    border-radius: 30px;
  }
  span{
    margin: auto 1rem;
  }
}


.react-datepicker-wrapper{
  width: 50% !important;
  border: 1px solid red;
}