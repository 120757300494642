@import "./base.scss";

.auth__container--links,
.errorboundary {
  display: flex;
}

.input--error {
  border-color: $primary-color;
}

.is--checked {
  font-style: normal;
  font-weight: 450;
  font-size: 14px;
  line-height: 20px;

  color: #323232;
}

.errorboundary {
  flex-direction: column;
}

.errorboundary {
  height: 100vh;
  align-items: center;
  justify-content: center;

  p {
    margin: 0 0 1.5rem 0;
  }
}
.error,
.landingPage--errors {
  color: $primary-color;
  font-size: 14px;
  margin-top: 0.3rem;
  font-weight: 500;

  &:before {
    content: url(../assets/alert.svg);
    padding: 0px 6px;
  }
}

.landingPage--errors {
  color: #fff;
}

input,
textarea,
select {
  border: 1px solid #ccc;
  border-radius: 6px;
  font-size: 14px;
  padding: 1rem;
  outline: none;
  // height: 54px;
  // width: 100%;
}

input,
textarea {
  font-size: 14px;
  padding: 1rem;
}

select {
  appearance: none;
  background: url("../assets/icons/dropdown-arrow.svg") no-repeat;
  background-position: right 0.9rem center;
  background-color: #fff;
  outline: none;
}

.loading__icon {
  display: block;
  margin: 0 auto;
  border-color: red;
}

.signup__errors,
.signup__success {
  list-style: none;
  padding: 0;
  margin: 0;
  li {
    padding: 1rem;
  }
}

.signup__errors {
  li {
    color: #7c3e08;
    background: #fcc18e;

    // width: 80%;
  }
}

.auth__container--links {
  // justify-content: space-between;
  margin-top: 4rem;
  text-align: center;

  p {
    font-size: 16px;
  }

  a,
  a:visited {
    text-decoration: none;

    &:hover > p {
      color: $primary-color;
    }
  }
}
