@import "../../../../styles/base.scss";

.header__details {
    width: 100%;

    .demo-overview {
        position: relative;
        margin-bottom: 100px;
        display: flex;
        flex-flow: column;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        margin-bottom: 40%;
        .text {
            width: 100%;
            display: flex;
            flex-flow: column;
            align-items: center;
            justify-content: center;

            h3 {
                font-style: normal;
                font-weight: bold;
                font-size: 60px;
                line-height: 91px;
                text-align: center;
                letter-spacing: -1px;
                color: #000000;
                width: 933px;
            }

            p {
                font-style: normal;
                font-weight: normal;
                font-size: 18px;
                line-height: 34px;
                text-align: center;
                color: #000000;
                width: 797px;
            }
        }

        .column {
            margin-top: 10px;
            width: 100%;
            display: flex;
            flex-flow: column;
            justify-content: center;
            align-items: center;
            height: 100%;

            video {
                width: auto;
                height: 380px;
            }
        }
    }
}

@media screen and (max-width: 600px) {
    .header__details {
        height: 340px;
        .demo-overview {
            flex-direction: column-reverse;
            // margin-top: -12rem;
            .text {
                h3 {
                    width: 343px;
                    font-size: 32px;
                    line-height: 42px;
                    letter-spacing: -1px;
                    text-align: left;
                    margin: 1rem 0;
                }
                p {
                    width: 343px;
                    font-size: 14.2px;
                    line-height: 18px;
                    text-align: left;
                }
            }

            .column {
                height: 260px;
                width: 100%;
                video {
                    width: 343px;
                    height: 380px;
                }
            }
        }
    }
}
@media (max-width: $break-point-xl) {
    .demo-overview {
        height: 100%;
        width: 100%;
        .column {
            h3 {
                font-style: normal;
                font-weight: bold;
                font-size: 32px;
                line-height: 42px;
                letter-spacing: -1px;
                color: #000000;
                text-align: left;
            }

            p {
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                line-height: 18px;
                text-align: left;

                color: #263238;
            }
        }
    }
}
