@import "../../../styles/base.scss";

.pricing__card,
.container {
  width: 80%;
}

.pricing__card__footer,
.pricing__card__flex,
.pricing__card__list,
.top__details {
  display: flex;
}

.pricing__card__list,
.top__details {
  justify-content: space-between;
}

.first__data,
.top__details__text p {
  color: $text-light;
}

.contact__box,
.pricing__card__sidebar,
.pricing__card__footer {
  position: relative;
}

.container {
  margin: 0 auto;
}

.header__details {
  display: flex;
  align-items: center;
  // padding-top: 7%;

  div {
    margin-bottom: 20%;
    margin-right: 200px;
    h3 {
      margin-bottom: 6%;
      font-size: 2.5rem;
      font-weight: bold;
      line-height: 4rem;
    }
  }

  img {
    width: 50%;
    height: 50%;
  }
}

// .header__details {
//   text-align: center;

//   h1 {
//     font-size: 4rem;
//   }

//   p {
//     font-size: 1.5rem;
//     margin-bottom: 3%;
//   }
// }

.pricing__content {
  background-color: rgba($color: $primary-color, $alpha: 0.03);
  padding: 5% 0 15%;

  .pricing__card__list {
    margin-top: 10%;
  }

  .pricing__card__flex {
    flex: 1;
  }

  .pricing__card__details {
    background-color: #ffffff;
    height: 100%;
    box-shadow: 20px 22px 24px rgba(87, 2, 6, 0.1);
    padding: 5% 5% 15%;

    p {
      font-weight: bold;
      font-size: 18px;
    }

    table {
      width: 100%;
      border-collapse: seperate;
      border-spacing: 0 1rem;

      td {
        font-size: 14px;
        img {
          margin-right: 3%;
        }
      }
    }
  }

  .pricing__card__sidebar {
    height: 100%;
    width: 10%;
    top: 20%;
  }

  .pricing__card__footer {
    color: #ffffff;
    padding-left: 5%;
    width: 90%;
    height: 20%;
    left: 10%;
    align-items: center;
  }
}

@media (max-width: $break-point-xl) {
  .container {
    width: 90%;
  }

  .header__details {
    flex-direction: column;
    height: 100%;
    margin-top: 20%;

    div {
      margin-bottom: 0;
      margin-right: 0;
      margin-top: 40px;

      h3 {
        font-size: 1.5rem;
        line-height: 2rem;
      }
    }

    img {
      margin-top: -100px;
      height: 100%;
      width: 100%;
    }
  }

  .pricing__card__list,
  .top__details {
    flex-direction: column;
  }

  .top__details img {
    margin: 6% 0;
  }

  .pricing__card__flex {
    margin-bottom: 30%;
    width: 100%;
  }
  .pricing__content {
    padding-top: 18%;

    .pricing__card__sidebar {
      width: 10%;
      padding-bottom: 75%;
      align-self: flex-end;
    }

    .pricing__card__footer {
      width: 102.5%;
    }
  }
}
