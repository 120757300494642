.header__details {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    padding-top: 80px;

    .hero__text {
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 20px;

        h1 {
            font-weight: 700;
            font-size: 56px;
            line-height: 74px;
            /* or 132% */

            color: #000000;
        }

        p {
            width: 584px;
            height: 108px;
            font-style: normal;
            font-weight: 450;
            font-size: 24px;
            line-height: 36px;

            color: #323232;
        }
    }

    .hero__image {
        background: linear-gradient(180deg, rgba(54, 44, 44, 0) -8.25%, rgba(18, 16, 16, 0.9) 104.76%);
        border-radius: 9.37143px;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
            position: relative;
            z-index: -3;
            width: 647px;
            border-radius: 9.37143px;
        }
    }
    .people {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        padding: 32px 48px;
        gap: 10px;

        position: absolute;
        width: 319px;
        height: 188px;
        left: 772px;
        top: 596px;

        background: #fff;
        border-radius: 10px;
        box-shadow: 2px 2px 40px rgba(41, 40, 40, 0.27);
    }
}

.main__content {
    margin-bottom: 2rem;
    padding: 40px 20px;
    background: #fffbf0;
    position: relative;
    z-index: 1;
    display: flex;
    align-items: center;
    flex-direction: column;

    .channel--list {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        flex-wrap: wrap;
        justify-content: space-between;
        padding: 0px;
        gap: 24px;
        // width: 95%;
        padding: 4rem 0rem;
        &__item {
            background: linear-gradient(180deg, rgba(54, 44, 44, 0) -8.25%, rgba(18, 16, 16, 0.9) 104.76%);

            border-radius: 9.37143px;

            position: relative;
            flex-basis: 49%;
            color: #fff;
            .bg--image {
                position: relative;
                width: 100%;
                z-index: -2;
                border-radius: 8px;
            }
            .content {
                display: flex;
                flex-direction: column;

                .chatbots--image {
                    position: absolute;
                    width: 199px;
                    height: 158.39px;
                    left: 85px;
                    top: 227px;
                }
                .socials--image {
                    position: absolute;
                    width: 361px;
                    height: 227px;
                    left: 159px;
                    top: 215px;
                    z-index: -2;
                }

                .emails--image {
                    position: absolute;
                    width: 200px;
                    height: 134px;
                    left: 425px;
                    top: 181px;
                }

                img::after {
                    content: "";
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    top: 0;
                    left: 0;
                    background: rgba(0, 0, 0, 0.5);
                }

                .text--content {
                    position: absolute;
                    bottom: 0;
                    padding: 32px;

                    h3 {
                        font-weight: 700;
                        font-size: 42px;
                        line-height: 55px;
                        color: #f4f4f4;
                    }

                    p {
                        font-weight: 450;
                        font-size: 18px;
                        line-height: 31px;
                        /* or 170% */

                        /* gray/3 */

                        color: #d8d8d8;
                    }
                }
            }
        }
    }
}

@media screen and (min-width: 360px) and (max-width: 600px) {
    .header__details {
        padding-top: 2rem;
        width: 100%;
        justify-content: center;
        .hero__text {
            width: 100%;
            gap: 10px;
            h1 {
                font-size: 32px;
                line-height: 43px;
                text-align: center;
            }
            p {
                font-weight: 450;
                font-size: 16px;
                line-height: 24px;
                width: 343px;

                text-align: center;
            }

            button {
                width: 100%;
            }
        }
        .hero__image {
            width: 375px;
            img {
                width: 100%;
            }
        }
        .people {
            position: absolute;
            left: unset;
            top: 769.83px;
            width: 269px;
            height: 158px;
            align-items: center;
        }
    }

    .main__content {
        margin-top: 5rem;
        h1 {
            font-weight: 700;
            font-size: 26px;
            line-height: 36px;
        }
        .channel--list {
            width: 100%;
            &__item {
                width: 100%;
                height: auto;
                flex-basis: 100%;

                .bg--image {
                    width: 100%;
                    height: auto;
                }

                .content {
                    .chatbots--image {
                        width: 105.33px;
                        height: 100.56px;
                        left: 45px;
                        top: 144.13px;
                    }
                    .socials--image {
                        width: 191.08px;
                        height: 120.16px;
                        left: 84.16px;
                        top: 113.8px;
                    }
                    .emails--image {
                        width: 105.86px;
                        height: 70.93px;
                        left: 234.97px;
                        top: 162.81px;
                    }
                    .text--content {
                        padding: 20px;
                        h3 {
                            font-size: 22.2315px;
                            line-height: 29px;
                        }
                        p {
                            font-weight: 450;
                            font-size: 12px;
                            line-height: 16px;
                        }
                    }
                }
            }
        }
    }
}
@media screen and (min-width: 1204px) and (max-width: 1298px) {
    .header__details {
        flex-direction: row;
        flex-wrap: nowrap;

        .hero__image {
            img {
                width: 523px;
            }
        }
        .people {
            left: 455px;
            top: 555px;
        }
    }

    .main__content {
        .channel--list {
            width: 1260px;
            padding: 2rem;

            &__item {
                flex-basis: 586px;
                .bg--image {
                    width: 100%;
                }

                .content {
                    .emails--image {
                        left: 380px;
                    }
                }
            }
        }
    }
}
@media screen and (min-width: 1300px) and (max-width: 1380px) {
    .header__details {
        flex-direction: row;
        flex-wrap: nowrap;

        .hero__image {
            img {
                width: 523px;
            }
        }
        .people {
            left: 510px;
            top: 555px;
        }
    }

    .main__content {
        .channel--list {
            width: 1260px;

            &__item {
                width: 618px;
                .bg--image {
                    width: 100%;
                }

                .content {
                    .emails--image {
                        left: 390px;
                    }
                }
            }
        }
    }
}

@media only screen and (width: 1440px) {
    .header__details {
        flex-direction: row;
        flex-wrap: nowrap;

        .hero__image {
            img {
                width: 523px;
            }
        }
        .people {
            left: 640px;
            top: 555px;
        }
    }

    .main__content {
        .channel--list {
            width: 1340px;

            &__item {
                flex-basis: 655px;
                .bg--image {
                    width: 100%;
                }

                .content {
                    .emails--image {
                        left: 390px;
                    }
                }
            }
        }
    }
}

@media screen and (width: 1200px) {
    .header__details {
        flex-direction: row;
        flex-wrap: nowrap;

        .hero__image {
            img {
                width: 463px;
            }
        }
        .people {
            height: 150px;
            left: 470px;
            top: 515px;
        }
    }

    .main__content {
        .channel--list {
            width: 100%;
            justify-content: center;
            align-items: center;

            &__item {
                flex-basis: 555px;
                .bg--image {
                    width: 100%;
                }

                .content {
                    .emails--image {
                        left: 340px;
                    }
                }
            }
        }
    }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
    .header__details {
        flex-direction: column;
        justify-content: center;
        flex-wrap: wrap;

        .hero__text {
            gap: 10px;
            h1 {
                font-size: 32px;
                line-height: 43px;
                text-align: center;
            }
            p {
                font-weight: 450;
                font-size: 16px;
                line-height: 24px;
                text-align: center;
            }
            button {
                width: 100%;
            }
        }

        .hero__image {
            img {
                width: auto;
            }
        }
        .people {
            height: 220px;
            width: 400px;
            left: unset;
            top: 1125px;
            img {
                width: 100%;
            }
        }
    }

    .main__content {
        margin-top: 4rem;
        .channel--list {
            justify-content: center;
            align-items: center;

            &__item {
                flex-basis: 92%;
                .bg--image {
                    width: 100%;
                }

                .content {
                    .emails--image {
                        left: 340px;
                    }
                }
            }
        }
    }
}

@media screen and (width: 2560px) {
    .header__details {
        flex-direction: row;
        flex-wrap: nowrap;

        .hero__image {
            img {
                width: 1223px;
            }
        }
        .people {
            height: 380px;
            width: 800px;
            left: 690px;
            top: 1025px;

            img {
                width: 100%;
                height: 310px;
            }
        }
    }

    .main__content {
        .channel--list {
            width: 100%;
            padding: 2rem;
            justify-content: center;

            &__item {
                flex-basis: 49%;
                .bg--image {
                    width: 100%;
                }

                .content {
                    .emails--image {
                        left: 380px;
                    }
                }
            }
        }
    }
}
