.modal {
    position: fixed;
    border-radius: 10px;
    background: white;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
    left: 50%;
    padding: 1.5rem;
    top: 50%;
    transform: translate(-50%, -50%);
    min-width: 30%;
    max-width: 50%;
    z-index: 100;

    img.cancel{
        position: absolute;
        cursor: pointer;
        width: 16px;
        height: 16px;
        top: 3%;
        right: 3%;
    }
}

.modal__content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 0.5rem 0.5rem;
}

@media (max-width : 768px){
    .modal{
        padding: 1.5rem;
        width: 500px;
        max-width: 85%;

    }
}