.api__container--key,
.api__container--url {
  background-color: #fff;
  box-shadow: 0px 0px 2px #ccc;
  border-radius: 6px;
  border: 1px solid #ccc;
  margin-bottom: 1rem;
  padding: 1rem;
  width: 60%;
  word-break: break-all;

  h4 {
    width: fit-content;
    margin-right: 1rem;
  }

  span {
    cursor: pointer;
  }
}
.api__container--key button,
.api__container--key header {
  display: flex;
  align-items: center;
}

.api__container--key {
  button {
    width: 25%;
    // padding: 0px;
    display: flex;
    align-items: center;
    text-align: center;

    &:hover {
      svg {
        filter: brightness(0) invert(1);
      }
    }
  }
}
