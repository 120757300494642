@import "../../styles/base.scss";

.form__container--custom,
.form__row--group,
.form__container--row {
  display: flex;
}
.form__container--custom *,
.form__container--row * {
  outline: none;
}

.form__container--row {
  input:disabled {
    color: rgb(31, 31, 31);
    cursor: not-allowed;
  }
}

.form__row--group,
.form__container--row {
  flex-direction: column;
}

.form__row--group {
  position: relative;
  margin: 1rem 0;
  width: 100%;

  input {
    width: 100%;
  }

  textarea {
    padding: 1rem;
  }
}

.form__container--row {
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;

  & > div {
    width: 48%;
  }
}

.form__container--custom {
  border: 1px solid #ccc;
  border-radius: 6px;
  position: relative;

  select,
  input {
    border: none;
  }

  select {
    width: 15%;

    // border-right: 1px solid $border-color;
  }

  span {
    cursor: pointer;
    display: inline-block;
    padding: 0 1rem;
  }

  input {
    flex: 1;
    width: 70%;
  }
}

.form__row--checkbox {
  display: flex;
  align-items: center;
  // border: 1px solid red;
  input {
    width: fit-content;
    margin-right: 1rem;
  }
}

@media screen and (max-width: $break-point-xl) {
  .form__container--row {
    flex-direction: column;

    & > div {
      width: 100%;
    }
  }

  .form__row--group {
    position: relative;
    margin: 1rem 0;
    & + div {
      width: 100%;
    }
  }
}

.form__row--radio {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  input[type="radio"] {
    height: 20px;
    // margin: 0 1rem;
    accent-color: $primary-color !important;
  }
  // width: fit-content;
  // width: 0;
}
