@import "../../../styles/base.scss";

.hero__container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: auto;

  img {
    margin-right: 100px;
  }
}

.section__otp--one,
.section__otp--two,
.section__otp--three {
  background: #f2f2f2;
}
.section__otp--one {
  background: #fffbf0;
  .container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 50px;
    .container--left {
      width: 45%;

      h1 {
        font-style: normal;
        font-weight: bold;
        font-size: 42px;
        line-height: 55px;
        /* or 130% */
        position: sticky;
        top: 0;
        width: 590px;
        color: #000000;
      }
    }
    .container--right {
      width: 50%;
      flex-direction: column;
      ul {
        li {
          margin: 0;
          display: flex;
          gap: 10px;
          align-items: center;
          flex-direction: row;

          span {
            font-style: normal;
            font-weight: 450;
            font-size: 18px;
            line-height: 31px;
            padding-top: 21px;
          }
        }
      }

      div.section__otp--img {
        img {
          width: 100%;
          height: 500px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
    strong {
      font-size: 19px;
      padding: 1rem 0;
    }
  }
}

.section__otp--two {
  background: rgba(255, 255, 255, 0.2);
  padding: 66px 0px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  position: relative;
  &::after {
    content: "";
    position: absolute;
    width: 534px;
    height: 534px;
    left: 1106px;
    top: 80px;
    // border: 1px solid green;
    border-radius: 50%;
    background: #f1faf8;
    filter: blur(100px);
  }

  h3 {
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    line-height: 42px;
    text-align: center;
    color: #000000;
    padding-bottom: 90px;
    z-index: 2;
  }

  .container {
    display: flex;
    align-items: center;
    gap: 139px;
    position: relative;
    z-index: 3;

    div {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin: 0 80px;
      .number {
        width: 50px;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        font-style: normal;
        font-weight: bold;
        font-size: 32px;
        line-height: 42px;

        color: #eb2931;
        background: #ffffff;
        border: 1px solid rgba(57, 65, 73, 0.2);
      }

      .header {
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        line-height: 36px;
        /* or 150% */

        text-align: center;

        width: 201px;

        color: #323232;
      }
    }
  }
}

.section__otp--three {
  background: #ffffff;
  position: relative;
  .circle {
    width: 506px;
    height: 506px;
    background: #fff1ce;
    filter: blur(400px);
    position: absolute;

    top: 50px;
  }
  .accordion {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 83px 0px;
  }
  .accordion__title {
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    line-height: 42px;
    color: #000000;
  }

  .accordion__list {
    position: relative;
    z-index: 3;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 0 14px 14px 14px;
    cursor: pointer;
    width: 980px;

    background: #ffffff;
    border: 1px solid rgba(57, 65, 73, 0.2);
    box-sizing: border-box;
    border-radius: 12px;

    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 18px 0px;

    .header {
      display: flex;
      width: 930px;
      justify-content: space-between;
      align-items: center;

      h3 {
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 31px;
        color: #000000;
      }
    }

    p {
      font-style: normal;
      font-weight: 450;
      font-size: 18px;
      line-height: 31px;
      /* or 170% */

      color: #8b8d92;
    }
  }
}

.header__details {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  button {
    margin: auto;
  }

  div {
    // margin-bottom: 20%;
    h3 {
      font-style: normal;
      font-weight: bold;
      font-size: 56px;
      line-height: 74px;
      text-align: center;

      color: #ffffff;
    }
    .third__header__paragraph {
      width: 637px;
      margin-bottom: 3%;
      font-style: normal;
      font-weight: 450;
      font-size: 24px;
      line-height: 36px;
      text-align: center;

      color: #f4f4f4;
    }
  }

  img {
    width: 50%;
    height: 50%;
  }
}

.header__main__text {
  width: 60%;
  font-size: 3rem;
}

@media screen and (max-width: 600px) {
  .header__details {
    div {
      overflow: hidden;
      h3 {
        font-style: normal;
        font-weight: bold;
        font-size: 32px;
        line-height: 42px;
        /* identical to box height, or 130% */

        text-align: center;

        color: #ffffff;
        margin: 0 0 8px 0;
      }
      .third__header__paragraph {
        font-style: normal;
        font-weight: 450;
        font-size: 16px;
        line-height: 24px;
        /* or 150% */

        text-align: center;
        width: 343px;

        /* gray/4 */

        color: #f4f4f4;
      }
      button {
        width: 100%;
      }
    }
  }

  .section__otp--one {
    padding-top: 0 !important;
    .container {
      flex-direction: column;
      padding-left: 16px;
      .container--left {
        h1 {
          font-style: normal;
          font-weight: bold;
          font-size: 24px;
          line-height: 36px;
          /* or 150% */
          width: 312px;
          color: #000000;
        }
      }

      .container--right {
        width: 100%;
        ul {
          padding: 5px;

          li {
            span {
              padding-top: 10px;
            }
            span:last-child {
              font-style: normal;
              font-weight: 450;
              font-size: 14px;
              line-height: 24px;
              width: 343px;
            }
          }
        }
      }
    }
  }

  .section__otp--two {
    padding: 0 !important;
    overflow: hidden;
    &::after{
      content: "";
      left: 0;
      
    }
    h3 {
      font-size: 24px;
      text-align: center;
      width: 272px;
    }

    .container {
      justify-content: center;
      flex-direction: column;
      gap: 41px;
      div {
        .header {
          font-style: normal;
          font-weight: 500;
          font-size: 18px;
          line-height: 31px;
          width: 181px;
          text-align: center;

          /* gray/1 */

          color: #323232;
        }
      }
    }
  }

  .section__otp--three {
    overflow: hidden;
    .circle {
      position: absolute;
      width: 280px;
      height: 280px;
      left: -83px;
      top: 527px;

      background: #f7e0a8;
      filter: blur(200px);
    }
    .accordion {
      padding: 41px 0px 37px 0;
      .accordion__title {
        text-align: center;
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        line-height: 36px;
        width: 272px;
      }
      .accordion__list {
        width: 343px;
        background: #ffffff;
        border: 1px solid rgba(202, 210, 218, 0.2);
        box-sizing: border-box;
        border-radius: 4px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        padding: 24px 16px;
        .header {
          h3 {
            font-weight: 500;
            font-size: 14px;
            line-height: 18px;
          }

          width: 100%;
        }

        p {
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 130%;
          /* or 18px */

          /* gray/2 */

          color: #969696;
        }
      }
    }
  }
}

@media screen and (max-width: $break-point-md) {
  .hero__container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin-top: 40px;

    img {
      margin-right: 60px;
      width: 80%;
      margin-bottom: 40px;
    }
  }
}

@media screen and (max-width: $break-point-xl) {
  .section__otp--one,
  .section__otp--two,
  .section__otp--three {
    grid-template-columns: repeat(1, 1fr);
  }

  .third__header__paragraph {
    width: 100%;
    margin-top: 8%;
    margin-bottom: 5%;
  }

  .header__contents {
    h1 {
      font-size: 1.8rem;
      line-height: 2rem;
    }

    p {
      font-size: 0.9rem;
      line-height: 1.3rem;
    }
  }

  .hero__container {
    img {
      width: 60%;
    }
  }
}
