@import "../../styles/base.scss";

.hero,
.hero__arrow,
.header,
.dropdown,
.demo-dropdown {
  display: flex;
  z-index: 1;
}

ul {
  list-style: none;
  padding-inline-start: 0;
}

a {
  text-decoration: none;
}

.header {
  flex-direction: column;
}

@keyframes bounceUpDown {
  0% {
    transform: translateY(30px);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes pulse {
  from {
    box-shadow: 0 0 0 0 $primary-color;
  }
}
.header {
  width: 100%;
  height: 100vh;
  padding: 4rem 0 6rem 0;
  // overflow: hidden;
}
.landing__page--bg {
  background: radial-gradient(50.58% 50.58% at 50% 64.76%, rgba(255, 255, 255, 0.65) 0%, rgba(255, 255, 255, 0.95) 100%),
    url("../../assets/landing__hero.webp");
  height: 100vh;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: relative;

  &::before {
    content: "";
    position: absolute;
    width: 530.67px;
    height: 560.67px;
    left: 0;
    top: -1px;

    background: #fff3db;
    filter: blur(400px);
  }

  &::after {
    content: "";
    position: absolute;
    width: 560.67px;
    height: 560.67px;
    left: 875.59px;
    top: 279.33px;
    background: #f1faf8;
    filter: blur(300px);
  }
}

.about__page--bg {
  background: linear-gradient(180deg, rgba(10, 10, 10, 0.76) 27.86%, rgba(0, 0, 0, 0.58) 114.05%),
    url("../../assets/images/about/new__about.svg");
  background-repeat: no-repeat;
  background-position: 40% 5%;
  background-size: cover;
}
.faqs__page--bg {
  background: linear-gradient(180deg, rgba(10, 10, 10, 0.76) 27.86%, rgba(0, 0, 0, 0.58) 114.05%),
    url("../../assets/images/faq__bg.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.otp__page--bg {
  background: linear-gradient(180deg, rgba(10, 10, 10, 0.76) 27.86%, rgba(0, 0, 0, 0.32) 114.05%),
    url("../../assets/images/otp__bg.jpeg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.voice__survey__page--bg {
  background: linear-gradient(180deg, rgba(10, 10, 10, 0.76) 27.86%, rgba(0, 0, 0, 0.32) 114.05%),
    url("../../assets/images/voice__survey.jpeg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.business__messaging__page--bg {
  background-image: linear-gradient(180deg, rgba(10, 10, 10, 0.76) 27.86%, rgba(0, 0, 0, 0.32) 114.05%),
    url("../../assets/images/business__messaging.jpeg");
  background-repeat: no-repeat;
  background-position: 40% 34%;
  background-size: cover;
}

.call__center__page--bg {
  background-image: linear-gradient(180deg, rgba(10, 10, 10, 0.76) 27.86%, rgba(0, 0, 0, 0.32) 114.05%),
    url("../../assets/images/call__center.jpeg");
  background-repeat: no-repeat;
  background-position: 40% 5%;
  background-size: cover;
}

.privacy__page--bg {
  height: 10vh;
  z-index: 99;
  background-color: #ffffff;
  position: relative;

  &::before {
    content: "";
    position: absolute;
    width: 530.67px;
    height: 560.67px;
    left: 0;
    top: -1px;

    background: #fff3db;
    filter: blur(400px);
  }

  &::after {
    content: "";
    position: absolute;
    width: 560.67px;
    height: 560.67px;
    left: 960.59px;
    top: 279.33px;

    background: #f1faf8;
    filter: blur(300px);
  }
}

.blog__page--bg {
  height: 30vh;
  background-color: #ffffff;
  position: relative;
  z-index: 99;
}
// .pricing__page--bg {
//   background: url("../../assets/images/pricing/pricing__bg.png");
// }

.demo__page {
  height: 3vh;
  background-color: transparent;
  position: relative;
  z-index: 99;
  padding-top: 4rem;
  padding-bottom: 4rem;
}
.ominichannel__page--bg {
  height: auto;

  .hero {
    width: 90%;
  }
}

.dropdown {
  position: absolute;
  background: #ffffff;
  color: #969696;
  font-weight: 600;
  padding: 0rem 1rem;
  border-radius: $border-radius;
  margin-top: 0.5rem;
  z-index: 99;
  border: 1px solid #d2d2d2;
  box-sizing: border-box;
  min-width: 220px;
  ul {
    li {
      min-width: 220px;
      border-bottom: 1px solid #d2d2d2;
      padding: 5px;
    }
    li:last-child {
      border-bottom: none;
      button {
        width: 150px;
      }
    }
  }
  a {
    cursor: pointer;
    z-index: 2;
    color: #000 !important;

    &:hover {
      color: $primary-color !important;
      border-bottom: none !important;
    }
  }
}

.demo-dropdown {
  position: absolute;
  background: #ffffff;
  color: #969696;
  font-weight: 600;
  padding: 0rem 1rem;
  border-radius: $border-radius;
  margin-top: 0.5rem;
  z-index: 99;
  border: 1px solid #d2d2d2;
  box-sizing: border-box;
  ul {
    li {
      border-bottom: 1px solid #d2d2d2;
      min-width: 190px;
      padding: 5px;
      button {
        width: 150px !important;
      }
    }
    li:last-child {
      border-bottom: none;
    }
  }
  a {
    cursor: pointer;
    z-index: 2;
    color: #000 !important;

    &:hover {
      color: $primary-color !important;
      border-bottom: none !important;
    }
  }
}

.hero__details {
  width: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
  height: auto;
}

.navbar {
  display: flex;
  width: 90%;
  margin: 0 auto;
  margin-top: -2rem;
  position: relative;
  z-index: 3;
  .navbar__menu__imgs {
    margin-right: auto;

    position: relative;

    .navbar__brand img {
      width: 165.5px;
      height: 65.75px;
    }

    .burger__menu__icon {
      display: none;
    }
  }

  .navbar__menu {
    display: flex;
    width: 75%;
    justify-content: space-between;
    // margin-top: 24px;
    align-items: center;
    .navbar__menu--item {
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 31px;
      a {
        // margin-top: 24px;
        &:hover {
          font-weight: 600;
          font-size: 18px;
          border-bottom: 1px solid #d2d2d2;
        }
      }
      li.navbar__menu--item {
        color: blue;
      }
      p {
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 31px;
        cursor: pointer;
        &:hover {
          font-weight: 600;
          font-size: 18px;
          border-bottom: 1px solid #d2d2d2;
        }
      }
      .arrow-down {
        cursor: pointer;
        &:before {
          content: "";
          border-style: solid;
          border-width: 0.1em 0.1em 0 0;
          display: inline-block;
          height: 0.4em;
          left: 0.15em;
          top: 0.25em;
          position: relative;
          width: 0.4em;
          vertical-align: text-top;
          transform: rotate(135deg);
        }
      }
    }

    .navbar__menu--item:nth-child(6) {
      margin-right: 4rem;
    }
  }
}

.menu__rule {
  display: none;
}

.hero {
  flex-wrap: wrap;
  justify-content: space-between;
  width: 80%;
  margin: 0 auto;
  height: 100%;

  button {
    font-weight: bold;
    width: 44%;
    cursor: pointer;
    box-shadow: 0 0 0 12px transparent;
    animation: pulse 1s;
    animation-iteration-count: infinite;
    position: relative;
    &:after {
      content: url(../../assets/icons/arrow-right.svg);
      position: absolute;
      opacity: 0;
      top: 24px;
      right: -20px;
      transition: 0.5s;
    }
    &:hover {
      padding-right: 24px;
      padding-left: 8px;
      &:after {
        opacity: 0.9;
        right: 20px;
      }
    }
  }

  .hero__arrow {
    flex: 50%;
    justify-content: center;
    align-items: center;
    animation: bounceUpDown 0.5s alternate-reverse infinite;
  }

  .hero__btn {
    background: $primary-color;
    width: 25% !important;
  }
}

@media screen and (max-width: 600px) {
  .business__messaging__page--bg,
  .call__center__page--bg,
  .otp__page--bg,
  .voice__survey__page--bg,
  .about__page--bg,
  .faqs__page--bg {
    // height: auto;
    background-position: center;
  }

  .landing__page--bg::after,
  .privacy__page--bg::after,
  .privacy__page--bg::before {
    display: none;
  }

  .privacy__page--bg,
  .demo__page,
  .blog__page--bg {
    height: auto;
    padding-bottom: 0px !important;

    background-color: #ffffff;
  }

  .navbar {
    .navbar__menu {
      .navbar__menu__imgs {
        width: 100%;
        .navbar__brand {
          img {
            width: 100%;
            height: 50px;
          }
        }
      }
      .navbar__menu--item {
        a,
        p {
          border-bottom: none !important;
        }
      }
    }
  }

  .demo-dropdown,
  .dropdown {
    position: initial;
    background: transparent;
    padding: 0rem;
    border-radius: $border-radius;
    margin-top: 0.5rem;
    z-index: 99;
    border: none;
    box-sizing: border-box;
    ul {
      margin-left: 20px;
      li {
        // min-width: 220px;
        border-bottom: none;
        padding: 5px 0px;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        /* identical to box height, or 170% */

        /* gray/2 */

        color: #969696;
        button {
          width: 150px !important;
        }
      }
      a {
        border-bottom: none;
        font-style: normal;
        font-weight: 500;
        font-size: 14px !important;
        line-height: 24px !important;
        color: #969696 !important;
      }
    }
    a {
      cursor: pointer;
      z-index: 2;
      color: #969696;

      &:hover {
        color: $primary-color !important;
        border-bottom: none !important;
      }
    }
  }

  .navbar {
    .white-bg {
      background-color: #ffffff;
    }

    .transparent-bg {
      background-color: transparent;
    }
  }
}

@media (max-width: $break-point-xl) {
  .landing__page--bg {
    &::before {
      position: absolute;
      width: 248px;
      height: 248px;
      left: -49px;
      top: -59px;

      background: #fff3db;
      filter: blur(200px);
    }
  }

  .hero {
    button {
      width: 180px;
      margin-bottom: 80px;
    }
  }

  .navbar {
    display: block;
    width: 90%;
    padding-bottom: 10px;
    .navbar__menu__imgs {
      display: flex;
      justify-content: space-between;

      .navbar__brand img {
        width: 85%;
        margin-right: auto;
      }

      .burger__menu__icon {
        display: block;
      }
    }

    .navbar__menu {
      display: none;
      flex-direction: column;
      // align-items: start;
      padding: 5px 5px;
      width: 363px;
      .navbar__menu--item {
        button {
          width: 95%;
        }
        a {
          font-size: 18px;
          font-weight: bold;
        }

        p {
          font-size: 18px;
          font-weight: bold;
        }
      }
    }
  }

  .menu__rule {
    display: block;
    margin: 5px 0;
    border: 0.5px solid $border-color;
  }
}

@media (min-width: 1024px) and (max-width: 1366px) {
  .navbar {
    .navbar__menu {
      .navbar__menu--item:nth-child(6) {
        margin-right: 1rem;
      }
    }
  }
}
