// @import "../../styles/base.scss";
@import "../Form-row/index.module.scss";

.container {
  margin: 0 auto;
  padding: 5% 0;
  width: 613px;
}

.contact__box {
  background: #fff;
  color: #000;
  position: relative;

  .container {
    display: flex;
    flex-wrap: wrap;
  }
}

.container--left {
  flex: 70%;
  width: 100%;

  h1 {
    text-align: center;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 36px;
    /* identical to box height, or 150% */

    color: #000000;
    margin-bottom: 20px;
  }
  p {
    opacity: 0.7;
  }
}
.container--right {
  flex: 30%;

  .contact__call {
    // margin-left: 20%;
    // margin-top: 20%;
    width: auto;

    .contact__call--text {
      font-size: 28px;
      line-height: 35px;
    }
    .contact__number {
      font-style: normal;
      font-weight: 450;
      font-size: 14px;
      line-height: 18px;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 2px;
      color: #000000;

      a {
        color: #126abd;
        text-decoration: none;
        cursor: pointer;
      }
    }
  }

  .contact__img {
    position: absolute;
    right: 0;
    bottom: 0;

    img {
      width: 100%;
    }
  }
}

@media (max-width: $break-point-xl) {
  .container {
    width: 90%;
    flex-direction: column;
  }

  .container--left {
    h1 {
      font-size: 32px;
    }
  }

  .container--right {
    .contact__call {
      margin: 10% 0 0;
      text-align: center;

      .contact__call--text {
        font-size: 18px;
        line-height: 20px;
      }

      .contact__number {
        font-size: 16px;
        line-height: 20px;
        flex-direction: column;
        gap: 5px;
      }
    }

    .contact__img {
      position: relative;
    }
  }
}
