@import "../../../../styles/base.scss";

.header__details {
  margin-top: 3rem;
  width: 100%;
  .header {
    text-align: left;
    margin-bottom: 10%;
    display: flex;
    justify-content: space-between;
    position: relative;
    overflow: hidden;
    width: 100%;
    .column {
      width: 50%;
      height: 580px;
      position: relative;

      video {
        position: absolute;
        bottom: 100px;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
    h3 {
      margin-bottom: 6%;
      margin-top: 0px;
      font-size: 2.8rem;
      font-weight: bold;
      line-height: 4rem;

      span {
        margin-bottom: 6%;
        font-size: 2.8rem;
        font-weight: bold;
        line-height: 4rem;
        color: $primary-color;
      }
    }
  }

  div {
    // margin-top: 120px;
    text-align: left;
    margin-bottom: 10%;
    margin-right: 100px;
    h3 {
      margin-bottom: 6%;
      font-size: 2.8rem;
      font-weight: bold;
      line-height: 4rem;

      span {
        margin-bottom: 6%;
        font-size: 2.8rem;
        font-weight: bold;
        line-height: 4rem;
        color: $primary-color;
      }
    }
  }

  img {
    margin-top: -50px;
    width: 50%;
    height: 50%;
  }
}

.form-section {
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  input {
    margin-bottom: 24px;
  }
  .small--filled {
    color: #fff;
    cursor: pointer;
    border: none;
    background-color: $primary-color;
    border-radius: 6px;
    padding: 12px 24px;
    font-size: 14px;
    width: 262px;
    font-size: 20px;
    font-weight: 500;
    height: 54px;
    text-align: center;
  }
}

.dialg--container {
  width: 548px;
  display: flex;
  flex-direction: column;
  padding: 20px;
  min-height: 70vh;
  max-height: 90vh;
  overflow: auto;
  .close-dialog {
    background: #eeeeee;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: 59px;
    cursor: pointer;
  }

  .options {
    margin-top: 3rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;

    .option {
      height: 44px;
      border: 1px solid #d8d8d8;
      box-sizing: border-box;
      border-radius: 4px;
      padding: 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;
    }
  }
}

.section__form__three {
  .form-head {
    display: flex;
    justify-content: space-between;
    align-items: center;

    span {
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 22px;
      display: flex;
      align-items: center;

      color: #8692a6;
    }
  }
  .form__btn__group {
    padding: 0.25rem 0;
    .component__right {
      button {
        width: 210px;
        padding: 10px;
      }
    }
  }
  .message {
    border: 1px solid #263238;
    box-sizing: border-box;
    border-radius: 6px;
    padding: 5px 14px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;

    p {
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 19px;
      display: flex;
      align-items: center;
      color: #263238;
    }

    span {
      cursor: pointer;
    }
  }
  .message-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 10px;
    background: #ffffff;
    border: 1px solid rgba(38, 50, 56, 0.1);
    box-sizing: border-box;
    border-radius: 8px;
    margin: 24px 0px;

    p {
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 22px;
      display: flex;
      align-items: center;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      padding-bottom: 10px;
      width: 100%;
      color: #263238;
    }

    p:last-child {
      border-bottom: none;
    }
  }
}

.voice-message {
  margin-top: 2rem;
  height: 55px;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
  border-radius: 8px;
  display: flex;
  padding: 10px;
  align-items: center;
}

.start-button-container {
  float: left;
  .start-button {
    display: flex;
    width: 195px;
    height: 54px;
    cursor: pointer;
  }

  .stop-button {
    display: flex;
    width: 185px;
    height: 54px;
    background: #ffffff;
    align-items: center;
    justify-content: center;
    border: 1px solid #eb2931;
    box-sizing: border-box;
    border-radius: 6px;
    color: #eb2931;
    cursor: pointer;
  }
}
.response {
  margin-top: 56px;
  p {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 23px;
    padding-top: 16px;
  }
}

@media screen and (max-width: 600px) {
  .header__details {
    width: 314px;
    .header {
      margin-bottom: 0%;
      width: 343px;
      flex-direction: column-reverse;
      overflow: inherit;
      .column {
        width: 333px;
        height: 290px;

        video {
          bottom: 30px;
        }
      }
      h3 {
        font-style: normal;
        font-weight: bold;
        font-size: 30px;
        line-height: 42px;
        letter-spacing: -1px;
        span {
          font-style: normal;
          font-weight: bold;
          font-size: 32px;
          line-height: 42px;
          letter-spacing: -1px;
        }
      }

      p {
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 18px;
      }
    }
  }

  .form-section {
    .small--filled {
      width: 100%;
    }
  }

  .dialg--container {
    height: 549px;
    min-height: 60vh;
    max-height: 90vh;

    img {
      display: flex;
      margin: 0 auto;
    }
    .response {
      h3 {
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        line-height: 31px;
        display: flex;
        align-items: center;
        text-align: center;

        color: #000000;
      }
      p {
        text-align: center;
        font-size: 14px;
        line-height: 18px;
        color: #8692a6;
      }
    }
  }

  .start-button-container {
    .start-button,
    .stop-button {
      width: 285px;
      height: 64px;
    }
  }
}

@media (max-width: $break-point-xl) {
  .header__details {
    flex-direction: column;
    height: 100%;
    padding-top: 10%;

    div {
      margin-bottom: 40px;
      margin-right: 0;
      margin-top: 0;

      h3 {
        font-size: 1.5rem;
        line-height: 2rem;
      }
    }

    img {
      margin-top: -100px;
      height: 100%;
      width: 100%;
    }
  }
  .dialg--container {
    width: 100%;
    padding: 0;
  }

  .section__one,
  .section__two,
  .section__three {
    padding: 15% 5%;
  }

  .form__btn__group,
  .section__form__one {
    flex-direction: column;
  }

  .section__form__three,
  .form__btn {
    width: 100%;
  }

  .section__title__box {
    .section__title {
      font-size: 1.5rem;
      line-height: 1.5rem;
    }
    .section__subtitle {
      width: 95%;
    }
  }

  .component__right {
    margin-right: 0;
    margin-bottom: 5%;
    margin-top: 3%;
  }
  .component__left {
    margin-left: 0;
  }
}
