@import "../../../../styles/base.scss";
@import "../index.module.scss";

.payments__table {
  table {
    background: #ffffff;
    font-weight: normal;
    text-align: center;
    box-shadow: 0px 8px 12px #cccccc;
    border-collapse: collapse;
    border-radius: 10px;
    width: 100%;
  }

  table tbody th {
    font-weight: normal;
  }

  table tbody th,
  table thead th {
    &:first-child {
      border-left: none;
    }
    &:last-child {
      border-right: none;
    }
  }

  table tbody th {
    border: 1px solid #444;
    padding: 0.8rem;

    img,
    span.verify {
      cursor: pointer;
      color: #004fc4;
    }

    span.declined {
      color: #f89742;
    }
    span.approved {
      color: #39d98a;
    }

    span {
      font-weight: bold;
    }
  }

  table thead th {
    padding: 1rem;
    border-left: 1px solid #444;
    border-right: 1px solid #444;
  }

  table tbody tr {
    &:last-child {
      border-bottom-style: hidden;
    }
  }
}

.document__container {
  img {
    width: 100%;
    height: 700px;
  }
}

.verify__container {
  color: $text-light;
  img {
    display: block;
    margin: auto;
    width: 200px;
    height: 100px;
  }
}

.text__row {
  display: flex;
  justify-content: space-between;
  font-size: 25px;
  span {
    display: inline-block;
  }
  span:nth-child(2) {
    width: 50%;
    color: #000;
  }
}

.bank__logo {
  width: 100px;
  height: 5px;
}

// .modal{
//     width: ;
// }
