@import "../../../../styles/base.scss";

.message__form--container {
  background-color: #fff;
  margin: 1.5rem 3rem;
  padding: 1rem 2rem;
}

.message__form--container {
  h5 {
    color: $primary-color-dark;
    font-size: 18px;
    font-weight: 400;
  }
}

.radio__buttons {
  padding: 1rem 0;
  display: flex;
  margin-left: -6rem;

  label {
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 20px;
    width: 420px;
    letter-spacing: 0.2px;
    margin-left: -5rem;
    color: #000;
  }
}

.recepient--type {
  padding: 1rem 0;
  display: flex;

  .radio {
    display: flex;
    flex-direction: row-reverse;
    justify-content: baseline;
    align-items: center;
    input {
      width: 50px;
    }
    input[type="radio"] {
      height: 30px;
      accent-color: red !important;
    }

    label {
      width: 250px;
    }
  }
}

.form--section {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 2rem;
  flex: 1;

  .form__group {
    display: flex;
    flex-direction: column;
    width: 100%;

    input,
    textarea {
      border-radius: 6px;
      padding: 1rem;
      accent-color: $primary-color;
    }
    textarea {
      height: 180px;
      border: 1px solid #ccc;
      resize: none;
    }
  }

  .requirements--info {
    display: flex;
    justify-content: space-between;
    text-align: right;
    // border: 1px solid red;
  }
}

.notice {
  background-color: hsl(35, 82%, 83%);
  padding: 15px;
  .requirement--head {
    display: flex;
    align-items: center;
    gap: 10px;
    h4 {
      margin-top: 0px;
    }
  }
  .requirement--list {
    border-left: 5px solid rgb(214, 174, 115);

    // background-color: hsl(35, 82%, 83%);
  }

  p {
    padding-left: 10px;
  }
}

.save--modal {
  margin-top: 2rem;
  display: flex;
  gap: 2rem;
}

.back--arrow {
  margin-left: 50px;
  margin-top: 1rem;
}

@media screen and (min-width: 360px) and (max-width: 600px) {
  .back--arrow {
    margin-top: 1rem;
    margin-left: 10px;
  }
  .message__form--container {
    width: 375px;
    margin: 1rem 0rem;
  }
  .radio__buttons {
    flex-direction: column;
    margin-right: 5rem;
    gap: 1.5rem;
    input[type="radio"] {
      accent-color: $primary-color !important;
    }
  }

  .recepient--type {
    flex-direction: column !important;

    .radio {
      input[type="radio"] {
        height: 20px;
      }
    }
  }
  .notice {
    width: 100%;
    background: #fff0cc;
    padding: 10px;
    .requirement--list {
      li {
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 18px;
        letter-spacing: 0.2px;

        color: #9b6e00;
      }
    }

    .requirement--head {
      display: flex;
      align-items: center;
      gap: 0.5rem;

      h4 {
        margin-top: 0px;
        font-style: normal;
        font-weight: bold;
        /* identical to box height */

        letter-spacing: 0.2px;

        color: #9b6e00;
      }
    }
  }
  .form--section {
    flex-direction: column;
    gap: 1.5rem;

    .form__group {
      width: 100%;
    }
  }
}
