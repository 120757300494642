@import "../../../styles/base.scss";

.container,
.testimonial__user,
.testimonial__cards--row,
.container__cards--row {
  display: flex;
  overflow: hidden;
}

ul {
  list-style: none;
  padding-inline-start: 0;
}

a {
  text-decoration: none;
}

@keyframes bounceUpDown {
  0% {
    transform: translateY(30px);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes pulse {
  from {
    box-shadow: 0 0 0 0 $primary-color;
  }
}

@keyframes bounceDown {
  from {
    top: 70%;
  }
}

@keyframes bounceUp {
  from {
    bottom: 10px;
  }
}

@keyframes bounceLeft {
  from {
    left: 40%;
    top: 55%;
  }
}

@keyframes bounceRight {
  from {
    right: 38%;
    top: 58%;
  }
}

@keyframes bounceBottomLeft {
  from {
    left: 40%;
  }
}

@keyframes bounceBottomRight {
  from {
    right: 40%;
  }
}

@keyframes logos {
  from {
    left: -20px;
  }
  to {
    left: 100%;
  }
}

@keyframes cardSlideRight {
  from {
    // transform: translate3d(-8.7rem, 0, 1rem);
    transform: translateX(-400px);
  }

  to {
    // transform: translate3d(0);
    transform: translateX(0px);
  }
}

@keyframes cardSlideLeft {
  from {
    // transform: translate3d(-8.7rem, 0, 1rem);
    transform: translateX(400px);
  }

  to {
    // transform: translate3d(0);
    transform: translateX(-0px);
  }
}

/* Keyframe Animations - added by Bobola*/
@keyframes scroll-left {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}

@keyframes scroll-right {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(100%);
  }
}

.new--header__details {
  display: flex;
  flex-direction: column;
  margin: auto;
  position: relative;
  z-index: 3;
  &::before {
    content: url("../../../assets/Frame1.webp");
    position: absolute;
    left: -260px;
    animation: bounceLeft 3s 2s alternate both;
    width: 54px;
    height: 25px;
  }
  &::after {
    content: url("../../../assets/Frame 3.webp");
    position: absolute;
    right: -260px;
    z-index: -3;
    animation: bounceRight 3s 2s alternate both;
  }

  div {
    margin-bottom: 20%;
    margin-top: 20%;
    // position: relative;
    &::before {
      content: url("../../../assets/Frame2.svg");
      position: absolute;
      top: 5%;
      right: 45%;
      animation: bounceDown 3s 2s alternate both;
    }

    &::after {
      content: url("../../../assets/Frame5.svg");
      position: absolute;
      bottom: -22%;
      right: 40%;
      z-index: -3;
      animation: bounceUp 3s 2s alternate both;
    }
    h3 {
      font-style: normal;
      font-weight: bold;
      font-size: 56px;
      line-height: 74px;
      text-align: center;

      color: #000000;
      &::before {
        content: url("../../../assets/Frame4.svg");
        position: absolute;
        border-radius: 20%;
        bottom: 5%;
        left: -35%;
        animation: bounceBottomLeft 3s 2s alternate both;
      }

      &::after {
        content: url("../../../assets/Frame06.png");
        position: absolute;
        bottom: 5%;
        right: -35%;
        animation: bounceBottomRight 3s 2s alternate both;
      }
    }
    .third__header__paragraph {
      width: 637px;
      font-style: normal;
      font-weight: 450;
      font-size: 18px;
      line-height: 31px;
      text-align: center;

      color: #000000;
      padding-bottom: 40px;
    }

    button {
      margin: auto;
    }
  }
}

.trusted--partner {
  background: #ffffff;
  padding: 30px 100px;
  position: relative;
  top: 5rem;
  width: 100%; 
  overflow: hidden;
  &__title {
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 36px;
    color: #000000;
    text-align: left;
    padding: 20px 0px;
  }

  &__logos {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 0px;
    margin: 36px 0px;
    flex-wrap: wrap;
    position: relative;
    animation: scroll-left 10s linear infinite; //added by bobola
    overflow: hidden; /* Keeps the logos within the container */
    white-space: nowrap; /* Prevents logos from wrapping */

    img {
      aspect-ratio: 3/2;
      width: 10%;
      object-fit: contain;
    }
  }

  &__logos2 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 0px;
    margin: 36px 0px;
    flex-wrap: wrap;
    position: relative;
    animation: scroll-left 10s linear infinite; //added by bobola
    overflow: hidden; /* Keeps the logos within the container */
    white-space: nowrap; /* Prevents logos from wrapping */

    img {
      aspect-ratio: 3/2;
      width: 10%;
      object-fit: contain;
    }
  }
}

.metrics--section {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  position: relative;
  margin-top: 5rem;
  overflow: hidden;
  &::after {
    content: "";
    position: absolute;
    width: 534px;
    height: 534px;
    left: 1106px;
    top: 120px;
    background: #f1faf8;
    filter: blur(50px);
  }
  h3 {
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 42px;
    /* identical to box height, or 130% */

    color: #000000;
    text-align: center;
  }

  .metric__items {
    display: flex;
    align-items: center;
    gap: 139px;
    z-index: 3;
    .metric__item {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin: 80px;

      .icon {
        background: #ffffff;
        padding: 16px;
        border: 1px solid rgba(57, 65, 73, 0.2);
        border-radius: 50%;
        width: 80px;
        height: 80px;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .number {
        font-style: normal;
        font-weight: 700;
        font-size: 42px;
        line-height: 55px;

        color: #000000;
      }
      .text {
        font-style: normal;
        font-weight: 450;
        font-size: 18px;
        line-height: 31px;
        text-align: center;
        color: #000000;
      }
    }
  }
}

.section__one {
  padding: 4rem 4rem 10rem 4rem;
  background: #fffbf0;

  .container {
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 0rem auto;
    width: 100%;
    padding: 4rem 0;

    h3 {
      font-style: normal;
      font-weight: bold;
      font-size: 42px;
      line-height: 55px;
      color: #000000;
      margin: auto;
    }

    .products {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      color: #ffffff;
      margin-top: 120px;

      position: relative;
      z-index: 2;
      &__card {
        border-radius: 8px;
        // flex: 47%;
        margin: 1.5%;
        padding: 2rem;
        display: block;
        padding-top: 80px;
        width: 537px;
        height: 641px;
        position: relative;
        z-index: 1;
        button {
          padding: 16px 24px;
          position: relative;
          width: 180px;
          height: 60px;
          text-align: left;

          background: #eb2931;
          border-radius: 4px;

          &::after {
            content: url(../../../assets/icons/arrow-right.svg);
            position: absolute;
            // opacity: 0;
            top: 20px;
            right: 30px;
            transition: 0.5s;
          }
        }
        h3 {
          font-style: normal;
          font-weight: bold;
          font-size: 32px;
          line-height: 42px;
          color: #ffffff;
        }

        p {
          padding-top: 16px;
          padding-bottom: 40px;
          font-style: normal;
          font-weight: 450;
          font-size: 24px;
          line-height: 36px;
          color: #f4f4f4;
        }

        &:nth-child(1) {
          background: linear-gradient(180.06deg, #423700 20.15%, rgba(0, 0, 0, 0) 108.83%),
            url("../../../assets/messaging.svg");
          background-repeat: no-repeat;
          background-position: center;
          background-size: cover;
          transform: translateY(90px);
        }
        &:nth-child(2) {
          background: linear-gradient(180.06deg, #083b51 23.89%, rgba(0, 83, 119, 0) 108.83%),
            url("../../../assets/feedback.svg");
          background-repeat: no-repeat;
          background-position: center;
          background-size: cover;
        }
        &:nth-child(3n) {
          background: linear-gradient(180.06deg, #0d0d0d 21.24%, rgba(0, 0, 0, 0) 108.83%),
            url("../../../assets/password.svg");
          transform: translateY(90px);
          background-repeat: no-repeat;
          background-position: center;
          background-size: cover;
        }
        &:nth-child(4) {
          background: linear-gradient(180.06deg, #03223f 21.55%, rgba(0, 82, 157, 0) 108.83%),
            url("../../../assets/cloud.svg");
          background-repeat: no-repeat;
          background-position: center;
          background-size: cover;
        }
      }
    }
  }
}
.section__two {
  background: #effcee;
  position: relative;
  padding: 2rem 0rem;
  // top: -16px;
  // padding-top: 20px;

  &::before {
    content: "";
    position: absolute;
    width: 565px;
    height: 365px;
    left: 36px;
    top: 120px;
    margin-top: 120px;
    background: #ffce55;
    filter: blur(200px);
  }

  &::after {
    content: "";
    position: absolute;
    width: 391px;
    height: 391px;
    left: 911px;
    top: 229px;
    margin-left: 100px;
    background: #fe6d6d;
    filter: blur(500px);
  }
  h3 {
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    line-height: 42px;
    text-align: center;
    color: #000000;
    padding-top: 2rem;
    position: relative;
    z-index: 1;
  }

  .container {
    position: relative;
    z-index: 1;

    justify-content: center;
    align-items: center;
    // margin: auto;
    width: 100%;
    padding: 4rem 0;
    gap: 60px;

    .container--left {
      // div.section__two--img {
      img {
        width: 550px;
        // height: 650px;
        border-radius: 8px;
      }
      // }
    }

    .container--right {
      .container__cards--row {
        // display: flex;
        // flex-wrap: wrap;
        // flex-direction: row;
        // align-items: center;
        display: grid;
        grid-template-columns: repeat(2, 316px);
        // grid-auto-rows: auto;
        gap: 15px;
        div.container__card {
          // width: 316px;
          height: 317px;
          position: relative;
          // margin: 0 3% 3% 0;
          // padding-right: 1.5rem;
          background: #ffffff;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          padding: 20px 22px 22px;

          border: 1px solid #f4f4f4;
          box-sizing: border-box;
          border-radius: 8px;

          .container__card--content {
            img {
              height: 50px;
              margin: 0px 0px 20px 0px;
            }
            p {
              font-style: normal;
              font-weight: bold;
              font-size: 18px;
              line-height: 31px;
              color: #000000;
            }
          }

          p {
            font-style: normal;
            font-weight: 450;
            font-size: 14px;
            line-height: 24px;

            color: #969696;
          }
        }
      }
    }
  }
}

.section__three {
  background: #2f080a;
  position: relative;
  z-index: 3;
  padding: 48px 0;
  top: -16px;

  .next--arrow {
    position: absolute;
    right: 105px;
    top: 55%;
    cursor: pointer;
    z-index: 3;
  }

  .prev--arrow {
    position: absolute;
    top: 55%;
    left: 105px;
    transform: rotate(180deg);
    cursor: pointer;
    z-index: 3;
  }
  .testimonial--controls {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    position: absolute;
    left: 48%;

    .testimonial--control {
      width: 18px;
      height: 18px;
      background: #fff;
      border-radius: 50%;
      cursor: pointer;
    }
    .testimonial--control__active {
      width: 18px;
      height: 18px;
      background: #eb2931;
      border-radius: 50%;
      cursor: pointer;
    }
  }
  h3 {
    font-style: normal;
    font-weight: bold;
    font-size: 42px;
    line-height: 55px;
    text-align: center;
    color: #ffffff;
    padding-bottom: 80px;
  }

  .testimonial--cards {
    padding: 70px 0px 90px 0;
    display: flex;
    justify-content: center;
    gap: 2px;
    align-items: center;
    position: relative;
    .testimonial--card {
      display: flex;
      flex-direction: column;
      background: #ffffff;
      border-radius: 4px;
      /* Inside auto layout */
      position: relative;
      flex: none;
      order: 0;
      flex-grow: 0;
      margin: 0px 23px;
      width: 372.51px;
      padding: 24px;

      svg {
        margin: 33px 0px;
        height: 40px;
      }

      .testimonial--card__text {
        font-style: normal;
        font-weight: 450;
        font-size: 14px;
        line-height: 24px;

        color: #323232;
      }

      .card--footer {
        display: flex;
        align-items: center;
        gap: 24px;
        margin-top: 115px;

        .customer--details {
          &__name {
            font-style: normal;
            font-weight: bold;
            font-size: 16px;
            line-height: 26px;
            color: #000000;
            flex: none;
            order: 0;
            flex-grow: 0;
            margin: 2.86545px 0px;
            padding: 0;
          }
          &__company {
            font-style: normal;
            font-weight: 450;
            font-size: 12px;
            line-height: 22px;
            color: #323232;
            flex: none;
            order: 1;
            flex-grow: 0;
            margin: 2.86545px 0px;
            padding: 0;
          }
        }
      }
    }

    .next {
      animation: cardSlideRight 1s ease-in 0s forwards;
    }
    .prev {
      animation: cardSlideLeft 1s ease-in 0s backwards;
    }
    .testimonial--card--active {
      width: 520px;
      // position: absolute;
      background: #9d1b21;
      border-radius: 4px;
      z-index: 3;

      svg {
        margin: 50px 23px;
        height: 40px;
      }

      .testimonial--card__text {
        font-style: normal;
        font-weight: 450;
        font-size: 20px;
        line-height: 34px;

        color: #f4f4f4;
        margin: 33px;
      }

      .card--footer {
        display: flex;
        align-items: center;
        gap: 24px;
        margin: 138px 32px 32px 49px;

        .customer--details {
          &__name {
            font-style: normal;
            font-weight: bold;
            font-size: 24px;
            line-height: 36px;

            color: #ffffff;
            flex: none;
            order: 0;
            flex-grow: 0;
            margin: 4px 0px;
            padding: 0;
          }
          &__company {
            font-style: normal;
            font-weight: 450;
            font-size: 18px;
            line-height: 31px;

            color: #d8d8d8;

            flex: none;
            order: 1;
            flex-grow: 0;
            margin: 4px 0px;
          }
        }
      }
    }

    .order-1 {
      order: 1;
    }
    .order-0 {
      order: 0;
    }
    .order-2 {
      order: 2;
    }
  }
}

.section__five {
  background: linear-gradient(331.03deg, $primary-color-dark 13.22%, $primary-color 86.79%);
  color: #ffffff;
  position: relative;
  // padding: 4rem 0;
}

address {
  font-style: normal;
}

.testimonial {
  padding: 2rem 0 7rem;
  position: relative;
  background: rgba($primary-color, 0.05);
  div.testimonial__cards--row {
    width: 100%;
    flex-direction: row;
    flex-flow: wrap;
    justify-content: space-between;
  }

  div.testimonial__card {
    flex: 47%;
    margin: 1.5%;
    background: #ffffff;
    border-radius: 20px;
    padding: 2rem;
    display: block;
    position: relative;
    img {
      width: 60px;
      height: 60px;
    }

    &:after {
      content: url(../../../assets/icons/testimonial-circle.svg);
      position: absolute;
      right: 5%;
      bottom: 5%;
    }

    .testimonial__user {
      justify-content: space-between;
      flex-direction: column;
      width: 90%;
      h4 {
        font-size: 18px;
      }
      p {
        width: 90%;
      }
    }

    &:nth-child(2) {
      background: linear-gradient(331.03deg, #750005 13.22%, #ff2d35 86.79%);
      color: #ffffff;
    }
    &:nth-child(2n) {
      transform: translateY(60px);
    }
  }
}

.testimonial__box {
  display: flex;
  justify-content: space-between;

  .testimonial__box__card__group {
    width: 50%;
  }

  .testimonial__text {
    width: 35%;
    color: $text-dark;
    align-self: center;

    h1 {
      font-weight: bold;
      font-size: 3.5rem;
      line-height: 3rem;
      margin-bottom: 6%;
    }

    p {
      font-weight: 600;
    }
  }
}

.section__four {
  background: #fffbf0;
  padding: 0 50px;
  position: relative;
  z-index: 2;
  top: -16px;
  .container {
    padding-top: 20px;
    display: flex;
    justify-content: space-between;
    // flex-direction: row !important;
    // flex-wrap: wrap;

    align-items: center;
    padding-bottom: 52px;

    .container--left {
      flex: 40%;
      flex-direction: column;
      border-radius: 25px;
      position: relative;
      z-index: 3;

      h3 {
        font-style: normal;
        font-weight: 500;
        font-size: 42px;
        line-height: 55px;
        color: #000000;
        width: 557px;
        padding-bottom: 40px;
      }

      p {
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 31px;
        letter-spacing: 0.03em;
        text-transform: uppercase;
        padding: 0;
        color: rgba(0, 159, 116, 0.72);
      }

      a {
        font-family: "circular_std_book";
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 31px;
        /* identical to box height, or 170% */

        text-decoration-line: underline;

        color: #126abd;
      }
    }

    .container--right {
      padding: 60px 60px;
      // overflow: hidden;
      position: relative;
      &__image {
        background: rgba(190, 203, 254, 0.52);
        border-radius: 8px;
        color: #ffffff;
        width: 648px;
        padding: 50px;
        position: relative;
        // overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
        &::before {
          content: "";
          position: absolute;
          width: 467px;
          height: 467px;
          left: -144px;
          top: -83px;

          background: #fff3db;
          filter: blur(80px);
        }

        &::after {
          content: "";
          position: absolute;
          width: 448px;
          height: 448px;
          left: 344px;
          top: 172px;

          background: #f1faf8;
          filter: blur(80px);
        }

        img {
          position: relative;
          z-index: 3;
        }
      }
    }
  }
}

.last__section {
  width: 100%;
  background: #fafafc;
  padding: 119px 30px;
  position: relative;
  z-index: 2;
  .details {
    width: 100%;
    display: flex;
    justify-content: center;
    div {
      h3 {
        font-style: normal;
        font-weight: bold;
        font-size: 42px;
        line-height: 55px;
        text-align: center;
        color: #000000;
        margin: auto;
      }
      p {
        font-style: normal;
        font-weight: 450;
        font-size: 20px;
        line-height: 36px;
        width: 757px;
        text-align: center;

        color: #8b8d92;
        margin-bottom: 48px;
      }

      button {
        width: 316px;
        height: 60px;
        margin: auto;
      }
    }
  }
}
.intro__text--title {
  font-weight: 500;
  font-size: 20px;
  line-height: 25px;
  letter-spacing: 0.03em;
  position: relative;
  padding: 0rem 2.5rem;

  &::before {
    content: "";
    width: 29px;
    border: 1px solid #000;
    left: 0;
    top: 50%;
    position: absolute;
  }
}

.intro__text--header {
  font-weight: bold;
  font-size: 3rem;
  line-height: 3rem;
  letter-spacing: -0.827586px;
  color: $text-dark;
}

.intro__text--p {
  font-weight: 450;
  margin: 2rem 0;
  font-size: 18px;
  line-height: 155%;
  color: rgba(0, 0, 0, 0.5);
  text-align: justify;
}

.learnmore--link {
  font-weight: bold;
  font-size: 20px;
  cursor: pointer;
  padding: 1rem 0;
  text-decoration-line: underline;
  text-decoration-color: $primary-color;
  text-underline-position: under;
  text-underline-offset: 7px;
  text-decoration-thickness: 5px;
}

@media screen and (max-width: 1440px) {
  .section__three {
    .prev--arrow {
      left: 5px;
    }
    .next--arrow {
      right: 5px;
    }
    .testimonial--cards {
      gap: 0px;
    }
  }

  .section__two {
    overflow: hidden;
    .container {
      // margin-left: 6%;
      width: 100%;
    }
  }
}

@media (width: 1920px) {
  .section__one {
    .container {
      .products {
        gap: 5px;
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .section__two {
    overflow: hidden;
    &::after {
      left: 412px;
      width: 280px;
    }
    .container {
      width: 100%;
      // margin-left: 30px;
      .container--left {
        div.section__two--img {
          img {
            width: 450px;
          }
        }
      }

      .container--right {
        .container__cards--row {
          div.container__card {
            flex: 100%;
          }
        }
      }
    }
  }
  .section__three {
    .prev--arrow {
      left: 30px;
    }
    .next--arrow {
      right: 30px;
    }
    .testimonial--cards {
      gap: 1px;
      .testimonial--card {
        width: 260px;
        height: 320px;
        svg {
          margin: 10px 0px 10px 0px;
        }
        .testimonial--card__text {
          font-size: 12px;
          line-height: 20px;
        }
        .card--footer {
          margin-top: 20px;
          .customer--details {
            &__name {
              font-size: 12px;
              line-height: normal;
            }
            &__company {
              font-size: 12px;
              line-height: normal;
            }
          }
        }
      }

      .testimonial--card--active {
        width: 330px;
        height: 380px;
        svg {
          margin: 20px 0px 0px 20px;
        }
        .testimonial--card__text {
          font-size: 14px;
          line-height: 24px;
        }
        .card--footer {
          margin: 20px 0px 20px 20px;
          .customer--details {
            &__name {
              font-size: 18px;
              line-height: normal;
            }
            &__company {
              font-size: 12px;
              line-height: normal;
            }
          }
        }
      }
    }
  }
}

@keyframes firstImage {
  from {
    top: 60%;
    left: 40%;
  }
}

@keyframes secondImage {
  from {
    top: 62%;
    left: 40%;
  }
}

@keyframes thirdImage {
  from {
    top: 62%;
  }
}

@media (width: 375px) {
  .new--header__details {
    width: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
    &::before {
      position: absolute;
      display: block;
      content: "";
      background-image: url("../../../assets/Frame1.webp");
      background-repeat: no-repeat;
      background-size: contain;
      height: 90px !important;
      width: 90px !important;
      left: -27.87% !important;
      top: -25.38% !important;
      bottom: 64.01%;
      animation: firstImage 3s 2s alternate both !important;
    }
    &::after {
      position: absolute;
      content: "";
      background-image: url("../../../assets/Frame 3.webp");
      background-repeat: no-repeat;
      background-size: contain;
      height: 90px !important;
      width: 90px !important;
      left: 109.4% !important;
      top: -35.13% !important;
      bottom: 71.82%;
      animation: secondImage 3s 2s alternate both;
    }

    div {
      // overflow: hidden;
      &::before {
        display: none;
      }
      &::after {
        content: url("../../../assets/frame_bottom_mobile.svg");
        position: absolute;
        left: 41.33%;
        right: 41.07%;
        top: 105.21%;
        animation: thirdImage 3s 2s alternate both;
      }
      h3 {
        font-style: normal;
        font-weight: bold;
        font-size: 32px;
        line-height: 42px;
        /* identical to box height, or 130% */

        text-align: center;

        margin: 0 0 8px 0;
        &::after,
        &::before {
          display: none;
        }
      }
      .third__header__paragraph {
        font-style: normal;
        font-weight: 450;
        font-size: 16px;
        line-height: 24px;
        /* or 150% */

        text-align: center;
        width: 143px;
      }
      button {
        width: 100%;
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .new--header__details {
    position: relative;
    &::before {
      position: absolute;
      display: block;
      content: "";
      background-image: url("../../../assets/Frame1.webp");
      background-repeat: no-repeat;
      background-size: contain;
      height: 130px;
      width: 130px;
      left: 5.87%;
      right: 65.25%;
      top: -18.38%;
      bottom: 64.01%;
      animation: firstImage 3s 2s alternate both !important;
    }
    &::after {
      position: absolute;
      content: "";
      background-image: url("../../../assets/Frame 3.webp");
      background-repeat: no-repeat;
      background-size: contain;
      height: 120px;
      width: 110px;
      left: 74.4%;
      right: 10.61%;
      top: -30.13%;
      bottom: 71.82%;
      animation: secondImage 3s 2s alternate both;
    }

    div {
      // overflow: hidden;
      &::before {
        display: none;
      }
      &::after {
        content: url("../../../assets/frame_bottom_mobile.svg");
        position: absolute;
        left: 41.33%;
        right: 41.07%;
        top: 105.21%;
        animation: thirdImage 3s 2s alternate both;
      }
      h3 {
        font-style: normal;
        font-weight: bold;
        font-size: 32px;
        line-height: 42px;
        /* identical to box height, or 130% */

        text-align: center;

        margin: 0 0 8px 0;
        &::after,
        &::before {
          display: none;
        }
      }
      .third__header__paragraph {
        font-style: normal;
        font-weight: 450;
        font-size: 16px;
        line-height: 24px;
        /* or 150% */

        text-align: center;
        width: 343px;
      }
      button {
        width: 100%;
      }
    }
  }

  .trusted--partner {
    padding: 0px 20px;
    top: 0;
    &__title {
      font-style: normal;
      font-weight: bold;
      font-size: 18.0443px;
      line-height: 31px;
      padding-left: 32px;
      color: #000000;
    }
    &__logos {
      animation: none;
      flex-wrap: wrap;
      justify-content: space-between;
      gap: 20px;
      margin: 0;
      img {
        aspect-ratio: 2/2;
        width: 20%;
        flex: 90px;
      }
    }

    &__logos2 {
      animation: none;
      flex-wrap: wrap;
      justify-content: space-between;
      gap: 20px;
      margin: 0;
      img {
        aspect-ratio: 2/2;
        width: 20%;
        flex: 90px;
      }
    }
  }

  .metrics--section {
    width: 375px;
    padding-bottom: 2rem;
    &::after {
      content: "";
      position: absolute;
      width: 344px;
      height: 285.54px;
      left: 18px;
      top: 159px;

      background: #f1faf8;
      filter: blur(300px);
      transform: rotate(17.87deg);
    }

    h3 {
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 30px;
      text-align: center;
      width: 289px;
      color: #000000;
    }
    .metric__items {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      padding: 0px;
      margin: 62px 0 0 0;
      gap: 20px;

      .metric__item {
        flex: none;
        order: 1;
        flex-grow: 0;
        margin: 0px 9px;

        .number {
          font-style: normal;
          font-weight: 700;
          font-size: 24px;
          line-height: 36px;
          text-align: center;
          width: 143px;
        }

        .text {
          font-style: normal;
          font-weight: 450;
          font-size: 14px;
          line-height: 20px;
          width: 143px;
          text-align: center;
        }

        img {
          padding: 8px;
        }
      }
      .metric__item:nth-child(2) {
        order: 2;
      }
    }
  }

  .section__one {
    padding: 20px 0px;
    .container {
      padding: 1rem 0;

      h3 {
        text-align: left;
        font-weight: bold;
        font-size: 24px;
        line-height: 29px;
        width: 343px;
      }

      .products {
        margin: 32px 0px;
        width: 100%;
        &__card {
          padding-top: 40px;
          margin-bottom: 10px;
          height: 400px;
          width: 343px;

          h3 {
            font-style: normal;
            font-weight: bold;
            font-size: 18px;
            line-height: 32px;
            color: #ffffff;
          }

          p {
            padding-top: 16px;
            padding-bottom: 10px;
            font-style: normal;
            font-weight: 450;
            font-size: 16px;
            line-height: 24px;
            text-align: left;
            color: #f4f4f4;
          }
          &:nth-child(1) {
            transform: none;
          }
          &:nth-child(3n) {
            transform: none;
          }

          button {
            margin-top: 10px;
            width: 150.8px;
            font-size: 11.4852px;
            text-align: left;
            position: relative;
            &::after {
              content: url(../../../assets/icons/arrow-right.svg);
              position: absolute;
              top: 18px;
              right: 20px;
              transition: 0.5s;
            }
          }
        }
      }
    }
  }
  .section__two {
    padding-bottom: 52px;
    position: relative;
    &::before {
      width: 100%;
      height: 461px;
      left: -43px;
      top: 0px;

      background: #ffce55;
      filter: blur(200px);
    }
    &::after {
      position: absolute;
      width: 343px;
      height: 391px;
      left: -46px;
      top: 1481px;

      background: #fe6d6d;
      filter: blur(200px);
    }
    h3 {
      font-style: normal;
      font-weight: bold;
      font-size: 24px;
      line-height: 36px;
      text-align: center;
      color: #000000;
      margin-bottom: 30px;
    }
    .container {
      padding: 1rem 0;
      gap: 0;
      flex-direction: column;
      justify-content: center;
      width: 100%;
      .container--left {
        // width: 100%;
        img {
          width: 343px;
          border-radius: 4px;
        }
      }

      .container--right {
        margin-top: 2rem;
        .container__cards--row {
          grid-template-columns: repeat(1, 343px);

          div.container__card {
            height: 250px;
            margin: 0;
            padding: 25px 0 0;

            .container__card--content {
              img {
                margin-left: 16px;
              }

              p {
                font-style: normal;
                font-weight: bold;
                font-size: 18px;
                line-height: 31px;
                /* identical to box height, or 170% */

                color: #000000;
                margin: 8px 16px;
              }
            }

            p {
              font-style: normal;
              font-weight: 450;
              font-size: 14px;
              line-height: 20px;
              /* or 143% */

              /* gray/2 */

              color: #969696;
              margin: 8px 16px;
            }
          }
        }
      }
    }
  }

  .section__three {
    padding: 30px 0;
    .prev--arrow {
      left: 4px;
      top: 50%;
    }
    .next--arrow {
      right: 4px;
      top: 50%;
    }
    .testimonial--controls {
      left: 40%;
      bottom: 10%;
    }

    h3 {
      width: 229px;
      height: 72px;
      font-style: normal;
      font-weight: bold;
      font-size: 24px;
      line-height: 36px;
      /* or 150% */
      margin: auto;
      text-align: center;

      color: #ffffff;
    }

    .testimonial--cards {
      z-index: -1;
      .testimonial--card {
        display: none;
      }

      .testimonial--card--active {
        width: 320px;
        height: auto;
        // position: initial;
        // animation: cardSlideRight 1s ease-in 0s forwards;
        svg {
          width: 32px;
          height: 32px;
          margin: 24px 10px;
        }

        .testimonial--card__text {
          font-style: normal;
          font-weight: 450;
          font-size: 14px;
          line-height: 20px;
          margin: 0 16px;
          color: #f4f4f4;
        }

        .card--footer {
          margin: 32px 16px;

          .customer--details {
            &__name {
              font-style: normal;
              font-weight: bold;
              font-size: 16px;
              line-height: 31px;
            }
            &__company {
              font-style: normal;
              font-weight: 450;
              font-size: 14px;
              line-height: 24px;

              color: #d8d8d8;
            }
          }
        }
      }
    }
  }

  .section__four {
    padding: 0px;
    .container {
      padding-top: 34px;
      flex-direction: column-reverse;
      align-items: center;
      .container--left {
        margin-bottom: 2rem;
        align-items: center;
        p {
          font-style: normal;
          font-weight: bold;
          font-size: 12px;
          line-height: 31px;
          padding-top: 24px;

          text-align: center;
          letter-spacing: 0.03em;
          text-transform: uppercase;

          color: rgba(0, 159, 116, 0.72);
        }
        h3 {
          z-index: 1;
          width: 343px;
          text-align: left;
          font-weight: bold;
          font-size: 24px;
          line-height: 29px;
          text-align: center;
        }

        a {
          justify-content: center;
        }
      }

      .container--right {
        padding: 0px;
        &__image {
          position: relative;

          width: 100%;
          padding: 1.5rem;
          display: flex;
          justify-content: center;
          overflow: hidden;

          &::before {
            content: "";
            position: absolute;
            width: 146.57px;
            height: 223.57px;
            left: -35.03px;
            top: -40.82px;

            background: #fff3db;
            filter: blur(21.1194px);
          }

          &::after {
            content: "";
            position: absolute;
            width: 146.57px;
            height: 223.57px;
            left: 62%;
            top: 50.81px;

            background: #f1faf8;
            filter: blur(21.1194px);
          }
          justify-content: center;
          img {
            position: relative;
            z-index: 1;
            width: 343px;
            margin: auto;
          }
        }
      }
    }
  }

  .last__section {
    display: flex;
    justify-content: center;
    padding: 16px;
    .details {
      div {
        width: 100%;
        h3 {
          text-align: center;
          font-weight: bold;
          font-size: 24px;
          line-height: 29px;
          padding-bottom: 8px;
        }
        p {
          text-align: center;
          width: 343px;
          font-size: 14px;
          line-height: normal;
          padding: 0;
        }

        button {
          font-family: "circular_std_book";
          width: 100%;
          height: 50px;
          margin-bottom: 22px;
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 24px;
        }
      }
    }
  }
}
