@import "../../../styles/base.scss";

.cards--container,
.cards--item {
    display: flex;
    gap: 30px;
    // justify-content: space-evenly;
}

.cards--container {
    margin-top: 2rem;
    margin-bottom: 2rem;
}

.cards--item {
    background-color: #fff;
    padding: 1.5rem;
}

.cards--item {
    align-items: flex-start;
    border-radius: 1rem;
    margin-right: 1rem;
    width: 250px;
    // border: 1px solid $border-color ;
    box-shadow: 0px 8px 10px #ccc;

    .metrics {
        display: flex;
        justify-content: space-between;
        align-items: center;
        align-content: center;
    }

    h2 {
        color: $primary-color-dark;
        margin: 0;
    }

    p {
        color: $text-light;
    }

    .icon {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background: #ffb8bb;
        // opacity: 0.3;
    }

    .icon,
    svg {
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.action--dialog {
    width: 176px;
    height: 143px;
    background: #ffffff;
    border-radius: 10px;
    box-shadow: 0px 15px 30px rgba(123, 123, 123, 0.2);
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: baseline;
    position: absolute;
    padding: 0 1rem;
    right: 150px;

    div {
        display: flex;
        justify-content: center;
    }

    .delete--action {
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 18px;

        /* Primary color */

        color: #eb2931;
        cursor: pointer;
    }

    .view--action {
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 18px;
        cursor: pointer;
        color: #219681;
    }

    .edit--action {
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 18px;

        color: rgba(21, 101, 216, 0.7);
        cursor: pointer;
    }

    .divider {
        width: 172px;
        position: absolute;
        right: 0;
        left: 0;
        height: 0px;
        top: 30px;
        margin-bottom: 10px;
        margin-right: 20px;
        border: 1px solid #c4c4c4;
    }

    .second-divider {
        width: 172px;
        position: absolute;
        right: 0;
        left: 0;
        height: 0px;
        bottom: 40px;
        margin-bottom: 10px;
        margin-right: 20px;
        border: 1px solid #c4c4c4;
    }
}
.table--top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    p {
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 23px;
        letter-spacing: 0.2px;

        color: #eb2931;
    }
}

.table--container {
    height: auto;
    margin-top: 20px;

    border-radius: 7px;
    .empty--table {
        top: 150px;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        position: relative;
        left: 370px;

        p {
            font-style: normal;
            font-weight: bold;
            font-size: 18px;
            line-height: 23px;
            text-align: center;
            letter-spacing: 0.5px;

            color: #828282;
        }
    }

    tbody .table--body {
        background: #ffffff;
        height: 80px;

        box-sizing: border-box;
        border-radius: 7px;
    }

    th:first-child {
        border-top-left-radius: 7px;
        border-bottom-left-radius: 0px;
    }
    th:last-child {
        border-top-right-radius: 7px;
        border-bottom-right-radius: 0px;
    }
    table th {
        background: #eb2931;
    }

    table tr:nth-child(odd) {
        background-color: rgba(229, 229, 229, 0.4);
    }
}

.create {
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 31px;

    color: #ffffff;
}

.deleteModal {
    width: 366px;
    height: 189px;

    background: #ffffff;
    border-radius: 20px;
    .buttons {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        margin-top: 2rem;
    }
    p {
        font-style: normal;
        font-weight: bold;
        font-size: 30px;
        line-height: 36px;
        display: flex;
        align-items: center;

        color: #000000;
    }
}
